import React from 'react'
import { PageSkeleton } from '../Section/PageSkeleton'
import { SectionHeading, SectionSubHeading, SectionText } from '../Section'
import { LogoInline } from '../LogoInline'

export const UI = () => {
  return (
    <PageSkeleton>
      <SectionHeading>Rób mniej zyskując więcej</SectionHeading>
      <SectionText>
        Bogaci w wieloletnie doświadczenie w tworzeniu zaawansowanych systemów
        informatycznych, oferujemy Państwu produkt, który nie tylko wykonuje
        powierzone mu zadania, ale również oferuje prostotę w użytkowaniu.
        <br />W końcu takie jest założenie systemów informatycznych - mają
        odciążać użytkownika od obowiązków, które da się zautomatyzować.
        <br />
        Dlatego w <LogoInline /> robimy wszystko w naszej mocy, żeby zwolnić
        Państwa z uzupełniania niepotrzebnych danych, wszędzie tam, gdzie nasz
        system może to zrobić samodzielnie.
      </SectionText>
      <SectionSubHeading>Prostota</SectionSubHeading>
      <SectionText>
        Mówi się, że proste rozwiązania są najtrudniejsze do stworzenia.
        <br />
        Rozwijając nasz system, pracujemy nad osiągnięciem jak najprostszych
        rozwiązań, dzięki czemu nasi użytkownicy mogą poświęcić minimum
        potrzebnego czasu na wykonanie niezbędnych czynności.
        <br />
        <br />
        Czynności takie jak wpisanie się do książki na strzelnicy byliśmy w
        stanie ograniczyć do dwóch kroków: przyłożenie karty członkowskiej do
        czytnika i złożenie podpisu.
        <br />W aplikacji mobilnej nie jest nawet wymagana karta członkowska.
        Użytkownik pozostaje zalogowany w aplikacji i jedyne co musi zrobić to
        wybrać strzelnicę na mapie i złożyć podpis.
        {/* TODO: add more info about non-mandatory signature */}
      </SectionText>
      <SectionSubHeading>UX - User Experience</SectionSubHeading>
      <SectionText>
        Pojęcie znane jako <i>User Experience</i>, czyli doświadczenie
        użytkownika korzystającego z systemu informatycznego, to zagadnienie,
        które ma na celu badać zachowania użytkowników i tworzyć rozwiązania
        najlepiej dopasowane do ich potrzeb, możliwości, percepcji itd.
        <br />
        W <LogoInline /> stosujemy nowoczesne rozwiązania UX uważane za
        standardy tworzenia interfejsów przyjaznych i funkcjonalnych dla
        użytkowników.
      </SectionText>
    </PageSkeleton>
  )
}
