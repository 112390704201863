import { SectionContent, SectionHeading } from '../Section'
import React from 'react'
import styled, { css } from 'styled-components'
import { FaStar, FaMobileAlt } from 'react-icons/fa'
import { GiCargoCrane } from 'react-icons/gi'
import { LogoInline } from '../LogoInline'
import { Box } from '@material-ui/core'

export const ProductOverview = () => {
  const videoWidth = window.innerWidth >= 960 ? 560 : window.innerWidth - 100
  const videoHeight = videoWidth / 1.77777

  return (
    <SectionContent id="jaktodziala">
      <SectionHeading>Poznaj nasze produkty</SectionHeading>

      <ProductTile>
        <iframe
          title="Elektroniczna książka pobytu na strzelnicy - jak to działa?"
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube-nocookie.com/embed/soPfLM6k61M"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <ProductTileDetails placement="left">
          <ProductHeading>
            <ProductIconBook />
            <ProductName>Elektroniczna książka</ProductName>
          </ProductHeading>
          <ProductDescription>
            Elektroniczna książka rejestru pobytu na strzelnicy to intuicyjne
            narzędzie, które zastąpi Twoją papierową książkę na strzelnicy.
            <br />
            <br />
            <strong>Jak to działa?</strong>
            <ol>
              <li>
                Wpisz na ekranie tabletu imię, nazwisko i adres zamieszkania
                (lub numer pozwolenia na broń).
              </li>
              <li>Złóż podpis na ekranie tabletu.</li>
              <li>
                Gotowe! Jesteś wpisany do książki rejestru pobytu na strzelnicy.
              </li>
            </ol>
          </ProductDescription>
          <MoreLink href="/produkt">Poznaj szczegóły</MoreLink>
        </ProductTileDetails>
      </ProductTile>

      <ProductTile>
        <ProductTileDetails>
          <ProductHeading>
            <ProductIconApp />
            <ProductName>Aplikacja mobilna</ProductName>
          </ProductHeading>
          <ProductDescription>
            <div>
              Z aplikacją mobilną <LogoInline /> wpisywanie się do książki jest
              jeszcze prostsze!
            </div>
            <br />
            Każdy, kto korzysta z aplikacji, może wpisać się do książki na
            Twojej strzelnicy na ekranie swojego telefonu.
            <br />
            <br />
            <strong>Jak to działa?</strong>
            <ol>
              <li>
                Użytkownik aplikacji dokonuje rejestracji i podaje swoje dane,
                bądź korzysta z konta klubowego
              </li>
              <li>
                Będąc w odległości poniżej 300 m od strzelnicy, można złożyć
                podpis na ekranie telefonu
              </li>
              <li>
                To wszystko! Dane użytkownika są zapisane i nie trzeba ich
                każdorazowo wpisywać.
              </li>
            </ol>
            <AppLinksWrapper>
              <Box mr={1}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.celpal.book"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AndroidAppImg
                    alt="Pobierz CelPal z Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/pl_badge_web_generic.png"
                  />
                </a>
              </Box>
              <a href="https://apps.apple.com/us/app/celpal/id1562006193?itsct=apps_box&amp;itscg=30200">
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pl-pl?size=140x40&amp;releaseDate=1618272000&h=cb233149b6b399e91db57af145d5e637"
                  alt="Pobierz z App Store"
                />
              </a>
            </AppLinksWrapper>
          </ProductDescription>
          <MoreLink href="/produkt">Poznaj szczegóły</MoreLink>
        </ProductTileDetails>
        <iframe
          title="Aplikacja mobilna - jak to działa?"
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube.com/embed/l8dMXKtR4xA"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </ProductTile>

      <ProductTile>
        <ProductTileDetails>
          <ProductHeading>
            <ProductIconAdmin />
            <ProductName>Administracja klubu</ProductName>
          </ProductHeading>
          <ProductDescription>
            Zarządzaj swoim klubem strzeleckim w prosty i bezpieczny sposób.
            <br />
            <br />
            <strong>Jak to działa?</strong>
            <ol>
              <li>Tworzenie, edycja i przegląd danych klubowiczów</li>
              <li>Eksport danych klubowiczów do pliku XLS</li>
              <li>Przydzielanie numerów kart członkowskich</li>
              <li>
                Możliwość błyskawicznego wpisu do książki przy pomocy karty
                członkowskiej
              </li>
              <li>Płatności członkowskie online</li>
              <li>Więcej...</li>
            </ol>
          </ProductDescription>
          <MoreLink href="/produkt">Poznaj szczegóły</MoreLink>
        </ProductTileDetails>
      </ProductTile>
    </SectionContent>
  )
}

const productIconStyles = css`
  font-size: 80px;
`

const ProductIconBook = styled(FaStar)`
  ${productIconStyles};
  color: #ffc107;
`

const ProductIconApp = styled(FaMobileAlt)`
  ${productIconStyles};
  color: #00ac5c;
`

const ProductIconAdmin = styled(GiCargoCrane)`
  ${productIconStyles};
  color: #e22b32;
`

const ProductTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 12px;
  margin: 30px auto 0;
  max-width: 600px;
  flex-basis: 0;
  flex-grow: 1;

  @media (min-width: 960px) {
    flex-direction: row;
    max-width: none;
    padding: 40px;
  }
`

const ProductTileDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
  order: -1;

  @media (min-width: 960px) {
    order: initial;
    ${p =>
      p.placement === 'left' ? 'margin-left: 32px;' : 'margin-right: 32px;'}
  }
`

const ProductHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`

const ProductName = styled.div`
  font-size: 26px;
  color: #3f4c56;
  margin-left: 32px;
`

const ProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`

const MoreLink = styled.a`
  display: inline-block;
  margin-top: 30px;
  background: #1284e4;
  color: #fff;
  border-radius: 8px;
  padding: 16px 20px;
  align-self: flex-start;
`

const AppLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AndroidAppImg = styled.img`
  width: 160px;
`
