import React from 'react'
import {
  Section,
  SectionContent,
  SectionHeading,
  SectionText
} from '../Section'
import { LogoInline } from '../LogoInline'
import { IoLogoFacebook } from 'react-icons/io'
import { Header } from '../Header'
import { Footer } from '../Footer'
import styled from 'styled-components'

export const Contact = () => {
  return (
    <ContactSection>
      <Header />
      <SectionContent>
        <SectionHeading>Jesteśmy do dyspozycji</SectionHeading>
        <SectionText>
          System <LogoInline /> powstaje dla Was. Zależy nam na Waszej
          satysfakcji oraz satysfakcji strzelców zrzeszonych w Waszych klubach i
          odwiedzających Waszą strzelnicę.
          <br />
          <br />Z chęcią odpowiemy na Wasze pytania i sugestie. Wierzymy, że
          dzięki nim nasze produkty będą coraz lepsze.
        </SectionText>
        <ContactData>
          <Link href="mailto:kontakt@celpal.net">kontakt@celpal.net</Link>
          <Link
            href="https://www.facebook.com/CelPalApp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoFacebook size="24px" /> Facebook
          </Link>
        </ContactData>
      </SectionContent>
      <Footer />
    </ContactSection>
  )
}

const ContactSection = styled(Section)`
  min-height: 100vh;
`

const ContactData = styled(SectionText)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
`

const Link = styled.a`
  display: flex;
  align-items: center;
`
