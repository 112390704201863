import { Section } from '../Section'
import { Header } from '../Header'
import { Intro } from './Intro'
import { Footer } from '../Footer'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { WelcomeOverview } from './WelcomeOverview'
import { ProductOverview } from './ProductOverview'
import { AgileTeaser } from './AgileTeaser'
import { Pricing } from '../Pricing'

export const Home = () => {
  const [shouldScroll, setShouldScroll] = useState(false)
  const goToWelcome = useCallback(() => {
    setShouldScroll(true)
  }, [])

  return (
    <>
      <IntroSection id="home" withMargin>
        <Header />
        <Intro onProceed={goToWelcome} />
      </IntroSection>
      <WelcomeOverview shouldScroll={shouldScroll} />
      <ProductOverview />
      <Pricing />
      <AgileTeaser />
      <Footer />
    </>
  )
}

const IntroSection = styled(Section)`
  min-height: 100vh;
`
