import { Section, SectionContent, SectionHeading, SectionText } from '../Section'
import React from 'react'
import { LogoInline } from '../LogoInline'

export const AgileTeaser = () => {
  return (
    <Section>
      <SectionContent>
        <SectionHeading>Zawsze na bieżąco</SectionHeading>
        <SectionText large>
          System <LogoInline /> to usługa typu SaaS (Software as a service).
          Dzięki temu zawsze korzystasz z najnowszej wersji naszego
          oprogramowania.
          <br />
          <br />
          W <LogoInline /> pracujemy w systemie{' '}
          <a
            href="https://pl.wikipedia.org/wiki/Programowanie_zwinne"
            target="_blank"
            rel="noopener noreferrer"
          >
            Agile
          </a>
          . Oznacza to, że stale rozwijamy nasze rozwiązanie wprowadzając nowe
          funkcje, a jeśli pojawią się błędy, poprawiamy je na bieżąco.
        </SectionText>
      </SectionContent>
    </Section>
  )
}
