import { createGlobalStyle } from 'styled-components'
import './fonts.css'

export const BaseStyles = createGlobalStyle`
  body {
    font-family: "Lato", sans-serif;
    margin: 0;
  }
  
  a {
    color: #2277c1;
    text-decoration: none;
    cursor: pointer;
  }
`
