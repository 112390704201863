import React from 'react'
import { SectionHeading, SectionSubHeading, SectionText } from '../Section'
import { LogoInline } from '../LogoInline'
import { PageSkeleton } from '../Section/PageSkeleton'

export const Safety = () => {
  return (
    <PageSkeleton>
      <SectionHeading>Twoje dane są bezpieczne</SectionHeading>
      <SectionText>
        Naszym priorytetem jest bezpieczeństwo Twoich danych oraz danych Twoich
        klubowiczów i gości strzelnicy.
      </SectionText>
      <SectionSubHeading>RODO</SectionSubHeading>
      <SectionText>
        Nasze produkty są w pełni zgodne z{' '}
        <a
          href="https://uodo.gov.pl/pl/404/224"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rozporządzeniem o ochronie danych osobowych
        </a>
        . Twoje dane przetwarzane przez <LogoInline /> nie będą udostępniane,
        ani powierzane żadnym podmiotom w celach innych, niż niezbędne do
        realizacji naszych usług. Nie sprzedajemy danych osobowych żadnym
        podmiotom.
      </SectionText>
      <SectionSubHeading>Cloud</SectionSubHeading>
      <SectionText>
        {/* TODO: Should we disclose this information? */}
        Stosujemy infrastrukturę Cloud marki{' '}
        <a
          href="https://cloud.google.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google
        </a>{' '}
        - jest to profesjonalne rozwiązanie, cenione przez specjalistów, które
        gwarantuje nieprzerwany dostęp do systemu <LogoInline /> oraz zapewnia
        bezkonkurencyjne bezpieczeństwo w warstwie sieciowej.
      </SectionText>
      <SectionSubHeading>OWASP 10</SectionSubHeading>
      <SectionText>
        <a
          href="https://owasp.org/www-project-top-ten/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Open Web Application Security Project
        </a>{' '}
        to projekt, który wyznacza standardy bezpieczeństwa w sieci. OWASP 10 to
        zbiór dziesięciu podatności bezpieczeństwa, których uniknięcie uważa się
        za niezbędne w celu dostarczania bezpiecznego oprogramowania. W{' '}
        <LogoInline /> przestrzegamy zasad OWASP 10 podczas wytwarzania i
        dostarczania oprogramowania.
      </SectionText>
    </PageSkeleton>
  )
}
