import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { Link } from 'react-router-dom'

export const MenuItem = ({ title, children, ...props }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const id = useMemo(
    () =>
      `a${Math.random()
        .toString()
        .replace('.', '')}`,
    []
  )
  const handleClick = () => {
    setMenuOpen(isOpen => !isOpen)
  }

  useEffect(() => {
    const handle = e => {
      if (!e.target.closest(`#${id}`)) {
        setMenuOpen(false)
      }
    }

    window.addEventListener('click', handle)
    return () => window.removeEventListener('click', handle)
  }, [id])

  if (!children || typeof children === 'string' || !children.length) {
    return (
      <MenuItemWrapper as={props.to ? Link : 'a'} {...props}>
        {title || children}
      </MenuItemWrapper>
    )
  }

  return (
    <MenuItemWrapper id={id} {...props} onClick={handleClick}>
      <span>{title}</span>
      {children && <SubMenu isOpen={menuOpen}>{children}</SubMenu>}
    </MenuItemWrapper>
  )
}

MenuItem.propTypes = {
  title: string
}

const MenuItemWrapper = styled.div`
  display: block;
  color: #fff;
  padding: 8px 0;
  margin: 16px;
  transition: color 100ms;
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  text-align: right;
  order: 1;

  &:hover {
    color: #efefef;
  }

  @media (min-width: 768px) {
    margin: 0 16px;
  }
`

const SubMenu = styled.div`
  margin-top: 8px;
  padding: 5px 0;

  @media (min-width: 768px) {
    display: ${p => (p.isOpen ? 'block' : 'none')};
    position: absolute;
    z-index: 100;
    background: #282c34;
    border-radius: 8px 8px;
    box-shadow: 0 0 1px #000;
  }
`
