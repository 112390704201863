import React from 'react'
import { PageSkeleton } from '../Section/PageSkeleton'
import { SectionHeading } from '../Section'
import { LogoInline } from '../LogoInline'
import styled from 'styled-components'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box
} from '@material-ui/core'

export const PrivacyPolicy = () => {
  return (
    <PageSkeleton>
      <SectionHeading>
        Aplikacja mobilna <LogoInline /> - Polityka Prywatności
      </SectionHeading>

      <Box display="flex" justifyContent="center">
        <Content>
          <p>
            Niniejsza Polityka Prywatności (zwana dalej „Polityką Prywatności”)
            określa sposób zbierania, przetwarzania i przechowywania danych
            osobowych koniecznych do realizacji usług świadczonych za
            pośrednictwem aplikacji mobilnej CEL-PAL (zwanej dalej „Aplikacją”)
            przez spółkę KODEUSZ SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ KRS
            0000830481 NIP 5833390660 REGON 385618552, Data rejestracji 21.
            lutego 2020 r. Adres siedziby: Jana Heweliusza 11 / 819, 80-890
            Gdańsk, Polska. Użytkownik przyjmuje do wiadomości, że
            Administratorem danych osobowych jest ww. spółka, (zwana dalej
            „Administratorem”) oraz, że powyżej wskazane dane są również danymi
            kontaktowymi.
            <br />
            <br />
            Polityka określa również prawa i obowiązki Użytkownika oraz
            Administratora.
            <br />
            <br />
            Przetwarzanie danych osobowych Użytkowników odbywa się zgodnie z
            ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą
            elektroniczną, Prawem telekomunikacyjnym oraz Rozporządzeniem
            Parlamentu Europejskiego i Rady (UE) 2016/679 w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (ogólne rozporządzenie o ochronie danych osobowych), zwanym dalej
            „Rozporządzeniem”.
            <br />
            <br />
            Użytkownik przyjmuje do wiadomości, że udostępnianie przez niego
            danych osobowych jest dobrowolne. Udostępnianie Administratorowi
            danych osobowych przez Użytkownika nastąpi po zaakceptowaniu
            Polityki Prywatności podczas rejestracji w Aplikacji, co następuje
            {/* TODO: is this description correct? */}
            poprzez kliknięcie w opcję "Załóż konto" w Aplikacji, podanie adresu
            email i hasła oraz kliknięcie przycisku "Utwórz konto".
          </p>
          <Title>
            Jakie informacje są zbierane i na jakiej podstawie prawnej?
          </Title>
          <ol>
            <li>
              Dane automatyczne: Administrator nie zbiera bez zgody Użytkownika
              danych osobowych, a wyłącznie dane nie posiadające takiego
              przymiotu, w szczególności dane demograficzne i dane dotyczące
              użytkowania Aplikacji. Zbieranie danych opisanych w zdaniu
              poprzednim odbywa się automatycznie (zwane dalej „dane zbierane
              automatycznie”). Dane zbierane automatycznie nie umożliwiają
              jednoznacznej identyfikacji Użytkownika. Dane zbierane
              automatycznie mogą służyć Administratorowi do poprawy jakości
              świadczonych usług, w szczególności w przypadku wystąpienia błędu
              Aplikacji. W sytuacji opisanej powyżej, dane zbierane
              automatycznie będą dotyczyły błędu Aplikacji, w tym stanu
              urządzenia mobilnego Użytkownika w chwili wystąpienia błędu,
              identyfikacji urządzenia mobilnego Użytkownika, fizycznej
              lokalizacji urządzenia mobilnego Użytkownika w chwili wystąpienia
              błędu. Wskazuje się, że Użytkownik nie ma możliwości zmiany bądź
              usunięcia danych zbieranych automatycznie.
            </li>
            <li>
              Dane kontaktowe: W przypadkach kontaktowania się Użytkownika z
              Administratorem, określonych w Regulaminie, Administrator będzie
              wymagał podania przez Użytkownika: adres email, imię, nazwisko,
              numer telefonu, (zwane dalej: „dane zbierane w celu nawiązania
              kontaktu”). Podanie przez Użytkownika danych zbieranych w celu
              nawiązania kontaktu jest dobrowolne, jednakże stanowić będzie
              wyłączną podstawę nawiązania kontaktu zwrotnego Administratora z
              Użytkownikiem oraz umożliwi Administratorowi weryfikację
              Użytkownika. Dane zbierane w celu nawiązania kontaktu będą
              wykorzystywane wyłącznie w celu umożliwienia poprawnej, pełnej i
              sprawnej komunikacji pomiędzy Administratorem a Użytkownikiem. W
              trakcie rejestracji w Aplikacji i korzystania z Aplikacji,
              Administrator może domagać się podania przez Użytkownika danych
              osobowych, w celu realizacji usług świadczonych przez
              Administratora za pośrednictwem Aplikacji.
            </li>
            <li>
              Dane z rejestracji konta: Podczas rejestracji konta w Aplikacji
              Użytkownik zobowiązany jest do podania swojego adresu e-mail. W
              przypadku korzystania przez Państwa z możliwości rejestrowania się
              w Aplikacji z poziomu serwisu społecznościowego Facebook lub przez
              konto Google, CEL-PAL otrzymuje z tych serwisów Państwa adres
              e-mail oraz imię i nazwisko, jeśli zostały one udostępnione. W
              razie zaistnienia takiej konieczności dane użytkowników mogą
              zostać udostępnione podmiotom upoważnionym na podstawie przepisów
              prawa.
            </li>
          </ol>

          <p>
            Przed rozpoczęciem korzystania z Funkcjonalności Aplikacji
            użytkownik zobowiązany jest do podania:
          </p>

          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>imienia i nazwiska;</li>
            <li>
              swojego adresu zamieszkania (nazwa ulicy, numer domu, numer
              mieszkania, kod pocztowy, miejscowość)
            </li>
            <li>
              numeru wydanego pozwolenia na broń oraz nazwę organu wydającego to
              pozwolenie;
            </li>
          </ul>

          <p>
            Dla korzystania z Funkcjonalności aplikacji przez Użytkownika
            konieczne jest podanie takich samych danych jak dane wymagane przez
            treść rozporządzenia Ministra Spraw Wewnętrznych i Administracji z
            dnia 15 marca 2000 r. w sprawie wzorcowego regulaminu strzelnic,
            zwane dalej Rozporządzeniem lub Rozporządzeniem MSWiA
            <br />
            <br />
            Zebrane dane osobowe Użytkownika będą wykorzystywane przez
            Administratora celem dostarczenia wybranej przez Użytkownika usługi
            w rozumieniu celów związanych z korzystaniem z funkcjonalności
            Aplikacji a także w celach wynikających z prawnie uzasadnionych
            interesów realizowanych przez Administratora, a także na podstawie
            zgody Użytkownika - w celach zgodnych z treścią udzielonej zgody.
            Podane przez Użytkowników dane osobowe będą przetwarzane na
            podstawie art. 6 ust. 1 lit. a), lit. b) i lit. f) Rozporządzenia.
          </p>

          <Title>Jak wykorzystujemy dane osobowe?</Title>

          <p>
            Postawą prawną przetwarzania przez danych osobowych jest: (i)
            Państwa dobrowolna i świadoma zgoda na przetwarzanie dotyczących
            Państwa danych osobowych lub (ii) konieczność przetwarzania takich
            danych w związku z wykonywaniem zawartej z Państwem umowy lub (ii)
            nasze prawnie uzasadnione interesy (w takim przypadku wyjaśnimy
            jakie to interesy).
          </p>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cel przetwarzania</TableCell>
                <TableCell>Podstawa prawna przetwarzania</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  przekazywanie Państwu informacji na temat zamówienia usługi /
                  realizacja zawartej umowy;
                </TableCell>
                <TableCell>
                  Art. 6 ust. 1 lit. b) RODOKonieczność wynikająca z umowy –
                  korzystamy z Państwa danych osobowych, aby wykonać
                  zobowiązania na mocy zawartej z Państwem umowy
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  kontaktowanie się z Państwem w sprawie oceny usługi lub
                  uzyskania informacji zwrotnych po świadczeniu usługi;
                </TableCell>
                <TableCell>
                  Art. 6 ust. 1 lit. f) RODOPrawnie uzasadnione interesy –
                  korzystamy z Państwa danych osobowych, aby skontaktować się z
                  Państwem w celu uzyskania informacji zwrotnych na temat
                  zamówionego przez Państwa produktu lub usługi.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  informowanie Państwa o zmianach usług i komunikacja w innych
                  celach. Na przykład, wykorzystamy Państwa dane kontaktowe, aby
                  odpowiedzieć na jakiekolwiek zadane przez Państwa pytania;
                </TableCell>
                <TableCell>
                  Art. 6 ust. 1 lit. f) RODOPrawnie uzasadnione interesy –
                  korzystamy z Państwa danych osobowych, aby przesyłać Państwu
                  aktualne informacje na temat naszych usług i odpowiadać na
                  Państwa pytania.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  umożliwianie, wzbogacanie i personalizacja doświadczeń
                  korzystania z naszych usług cyfrowych udostępnianych poprzez
                  stronę internetową, portale mobilne i aplikacje;
                </TableCell>
                <TableCell>
                  Art. 6 ust. 1 lit. f) RODOPrawnie uzasadnione interesy –
                  korzystamy z Państwa danych osobowych, aby dostosować
                  doświadczenie korzystania z naszych usług cyfrowych do Państwa
                  potrzeb.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  przeprowadzanie kontroli bezpieczeństwa w celu ochrony przed
                  nieuczciwymi transakcjami oraz zapobieganiu i wykrywaniu
                  przestępstw;
                </TableCell>
                <TableCell>
                  Art. 6 ust. 1 lit. f) RODOPrawnie uzasadnione interesy –
                  korzystamy z Państwa danych osobowych w celu ochrony przed
                  nielegalnymi działaniami. W pewnych przypadkach możemy również
                  być zobowiązani do ujawnienia Państwa danych osobowych (na
                  przykład organom ścigania) na mocy obowiązujących przepisów
                  prawa.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>reakcja na wszelkie roszczenia wobec nas.</TableCell>
                <TableCell>
                  Art. 6 ust. 1 lit. f) RODOPrawnie uzasadnione interesy –
                  korzystamy z Państwa danych osobowych, aby zareagować na
                  wszelkie roszczenia wysuwane przez Państwa w stosunku do nas.
                  W pewnych przypadkach możemy również być zobowiązani do
                  ujawnienia danych osobowych na mocy obowiązujących przepisów
                  prawa (na przykład w związku z postępowaniem przed sądem).
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <p>
            Jednocześnie zaznaczamy, że strona internetowa i aplikacja nie są
            przeznaczone dla dzieci, w związku z czym nie gromadzimy danych
            dotyczących dzieci, o ile wiemy, że mamy do czynienia z takimi
            danymi. Przyjmuje się, że dane podane przez Użytkownika, są danymi
            osoby której dotyczą.
          </p>

          <Title>Jak długo przechowujemy dane?</Title>

          <p>
            Na mocy obowiązujących przepisów prawa możemy przechowywać Państwa
            dane osobowe nie dłużej, niż jest to konieczne do zrealizowania
            celów, dla których je wykorzystujemy. Okres, przez których
            przechowujemy Państwa dane osobowe zostanie ustalony w oparciu o
            szereg kryteriów obejmujących cele, w jakich wykorzystujemy dane,
            ilość i wrażliwość danych, potencjalne ryzyko związane z
            nieupoważnionym wykorzystaniem lub ujawnieniem danych oraz nasze
            zobowiązania wynikające z przepisów prawnych i nadzorczych.
          </p>

          <Title>Czy dzielimy się z kimś danymi osobowymi?</Title>

          <p>
            Możemy także ujawniać Państwa dane osobowe (powierzyć ich
            przetwarzanie) stronom trzecim, w tym w następujących
            okolicznościach:
            <br />
            <br />
            Możemy udostępniać Państwa dane osobowe podmiotom zewnętrznym, w tym
            policji oraz innym organom ścigania, upoważnionym do ich otrzymania
            na podstawie obowiązujących przepisów prawa.
            <br />
            <br />
            Możemy przekazać dane osobowe naszym ubezpieczycielom w przypadku
            zgłoszenia lub potencjalnego zgłoszenia roszczenia wobec nas.
            <br />
            Jeżeli będziemy sprzedawać lub nabywać jakąkolwiek firmę lub aktywa,
            możemy ujawnić przechowywane przez nas dane osobowe potencjalnemu
            sprzedawcy lub kupującemu zainteresowanemu taką firmą lub aktywami.
            Jeżeli nasza spółka lub zasadniczo wszystkie jej aktywa zostaną
            przejęte przez stronę trzecią (lub zostaną objęte reorganizacją
            wewnątrz naszej grupy korporacyjnej), przechowywane przez nas dane
            osobowe wejdą w skład przekazywanych aktywów.
            <br />
            <br />
            Możemy przekazać Państwa dane osobowe stronom trzecim jeżeli
            będziemy zobowiązani do ujawnienia takich danych lub podzielenia się
            nimi, aby spełnić jakiekolwiek wymogi prawne (również w związku z
            nakazem sądowym) lub w celu wyegzekwowania lub wprowadzenia w życie
            jakichkolwiek porozumień zawartych z Państwem lub Państwa
            dotyczących (w tym porozumień pomiędzy Państwem a nami (lub jedną
            lub większą liczbą naszych spółek zależnych)); lub w celu ochrony
            naszych praw, własności lub bezpieczeństwa lub prawa własności lub
            bezpieczeństwa naszych klientów, pracowników lub innych stron
            trzecich.
            <br />
            <br />
            Możemy dzielić się danymi anonimowymi lub zbiorczymi (takimi jak
            zbiorcze dane statystyczne lub inne dane anominizowane) ze stronami
            trzecimi.
          </p>
          <Title>Gdzie są przetwarzane nasze dane osobowe?</Title>

          <p>
            Dane osobowe przetwarzane są na obszarze EOG (Europejski Obszar
            Gospodarczy).
          </p>

          <Title>Jakie są zobowiązania Administratora?</Title>
          <p>
            Administrator gwarantuje zapewnienie odpowiednich środków
            technicznych i organizacyjnych zapewniających bezpieczeństwo
            przetwarzanych danych osobowych, w szczególności uniemożliwiających
            dostęp do nich nieuprawnionym osobom trzecim, lub ich przetwarzania
            z naruszeniem przepisów powszechnie obowiązującego prawa,
            zapobiegających utracie danych osobowych, ich uszkodzeniu lub
            zniszczeniu.
            <br />
            <br />
            W zakresie retencji danych Administrator oświadcza, że Dane osobowe
            Użytkownika będą przechowywane tak długo, jak będzie to konieczne do
            realizacji przez Administratora usług świadczonych za pośrednictwem
            Aplikacji. Oznacza to, że dane osobowe będą przechowywane przez
            okres korzystania z Aplikacji a także po zakończeniu przez
            Użytkownika korzystania z Aplikacji, na czas zgodny z przepisami
            prawa, a w przypadku prawnie usprawiedliwionych celów Administratora
            oraz na podstawie Państwa zgody - do czasu cofnięcia zgody.
            <br />
            <br />
            Administrator ma prawo udostępniania danych osobowych Użytkownika w
            sposób opisany powyżej w punkcie „Czy dzielimy się z kimś danymi
            osobowymi”?
            <br />
            <br />
            Administrator informuje, że będzie przetwarzał, zgodnie z przepisami
            prawa, dane dotyczące numeru i rodzaju urządzenia końcowego
            Użytkownika (w tym numer IP) oraz czasu połączenia Użytkownika z
            Aplikacją. Dane te przetwarzane są w szczególności w celach
            technicznych oraz w celu zbierania ogólnych danych statystycznych.
            <br />
            <br />
            Administrator dołoży najwyższych starań, aby zabezpieczyć dane
            Użytkowników przed nieupoważnionym dostępem innych osób oraz przed
            ich zgubieniem/ zniszczeniem, poprzez zastosowanie odpowiednich
            zabezpieczeń technicznych i organizacyjnych.
            <br />
            <br />
            Administrator zastrzega sobie prawo wprowadzenia zmian w Polityce
            Prywatności, o czym poinformuje Użytkownika za pośrednictwem
            Aplikacji. Jeżeli Użytkownik nie wyrazi zgody na wprowadzone zmiany,
            zobowiązany jest trwale usunąć Aplikację ze swojego urządzenia
            mobilnego.
          </p>

          <Title>Jakie są uprawnienia Użytkownika?</Title>

          <p>
            Użytkownik ma prawo dostępu do swoich danych osobowych za
            pośrednictwem Aplikacji. W przypadku trwałego usunięcia przez
            Użytkownika danych osobowych, koniecznych do realizacji przez
            Administratora usług świadczonych za pośrednictwem Aplikacji,
            Użytkownik utraci możliwość korzystania z tych usług. Każdemu
            Użytkownikowi przysługuje prawo żądania od Administratora dostępu do
            danych osobowych dotyczących jego osoby, ich sprostowania, usunięcia
            lub ograniczenia przetwarzania. Żądanie to należy zgłosić na adres{' '}
            <a href="mailto:rodo@celpal.net">rodo@celpal.net</a>.
            <br />
            <br />
            Każdemu Użytkownikowi przysługuje prawo do cofnięcia zgody w
            dowolnym momencie bez wpływu na zgodność z prawem przetwarzania,
            którego dokonano na podstawie zgody przed jej cofnięciem.
            Oświadczenie o cofnięciu zgody powinno zostać skierowane na adres{' '}
            <a href="mailto:rodo@celpal.net">rodo@celpal.net</a>
            <br />
            <br />
            Każdemu Użytkownikowi przysługuje prawo wniesienia skargi do Prezesa
            Urzędu Ochrony Danych Osobowych, gdy uzna on, iż przetwarzanie
            dotyczące jego danych osobowych narusza przepisy prawa. Szczegółowe
            wytyczne w zakresie wymogów formalnych skargi znajdują się na
            stronie{' '}
            <a href="https://www.ksoin.pl/jak-zglosic-naruszenie-rodo-i-zlozyc-skarge-do-uodo/">
              https://www.ksoin.pl/jak-zglosic-naruszenie-rodo-i-zlozyc-skarge-do-uodo/
            </a>
            .
            <br />
            <br />
            Użytkownik ma dostęp do swoich danych osobowych oraz treści
            wyrażonych zgód, jak również ma możliwość ich edycji/ uzupełniania
            korzystając w tym celu z narzędzi dostępnych w ramach Aplikacji.
            <br />
            <br />
            Użytkownik może również dobrowolnie udostępniać informacje o swoim
            położeniu (dane geolokalizacyjne) oraz wyrazić zgodę na otrzymywanie
            powiadomień typu Push. Wskazuje się, że podanie danych oraz danych
            geolokalizacyjnych jest dobrowolne, lecz niezbędne do korzystania z
            funkcjonalności Aplikacji. Brak podania takich danych osobowych
            uniemożliwia korzystanie z niektórych funkcjonalności Aplikacji.
          </p>

          <Title>Aktualizacje Polityki Prywatności</Title>

          <p>
            Wszelkie zmiany dotyczące postanowień w ramach Polityki Prywatności,
            spowodowane w szczególności wzbogaceniem katalogu usług oferowanych
            w ramach Aplikacji, modernizacji Aplikacji, zmianami w systemie
            prawa w zakresie ochrony prywatności, będą komunikowane użytkownikom
            poprzez zmieszczenie informacji na stronie Aplikacji oraz poprzez
            przesłanie na adres mailowy podany podczas rejestracji, odpowiedniej
            informacji. Wszelkie zmiany wchodzić będą w życie w terminie nie
            krótszym niż 7 dni od momentu poinformowania użytkownika o
            planowanych zmianach w sposób wskazany w zdaniu poprzednim.
          </p>
        </Content>
      </Box>
    </PageSkeleton>
  )
}

const Title = styled.div`
  font-weight: bold;
  margin: 16px 0;
`

const Content = styled.div`
  text-align: justify;
  max-width: 700px;
  margin: 0 32px;
`
