import React from 'react'
import { PageSkeleton } from '../Section/PageSkeleton'
import { IoBan } from 'react-icons/io5'
import { Box, Typography } from '@material-ui/core'

export const NotFound = () => {
  return (
    <PageSkeleton>
      <Box display="flex" alignItems="center" mt={5}>
        <Box mr={3}>
          <IoBan size={64} />
        </Box>
        <Typography variant="h4">Ten adres nie istnieje.</Typography>
      </Box>
    </PageSkeleton>
  )
}
