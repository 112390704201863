import styled from 'styled-components'

export const Content = styled.div`
  text-align: justify;
  max-width: 700px;
  margin: 0 32px;

  ol,
  ul {
    padding-left: 32px;
  }

  ol {
    list-style: none;
    counter-reset: terms;

    & > li {
      counter-increment: terms;

      &:before {
        content: counters(terms, '.') '. ';
      }
    }
  }

  ol ol ol {
    list-style-type: upper-alpha;

    li:before {
      content: '';
    }
  }

  li {
    margin-top: 16px;
  }

  & > ol > li {
    margin-top: 32px;
  }
`
