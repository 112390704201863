import React from 'react'
import { Logo } from '../Logo'
import styled from 'styled-components'

export const LogoInline = props => <LogoStyled {...props} />

const LogoStyled = styled(Logo)`
  width: auto;
  height: 1em;
  vertical-align: middle;
`
