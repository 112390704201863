import React, { useState } from 'react'
import { Header } from '../Header'
import {
  Section,
  SectionContent,
  SectionHeading,
  SectionSubHeading
} from '../Section'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@material-ui/core'
import { questionsGroups } from './questions'
import { FaFacebookMessenger } from 'react-icons/fa'
import { MdExpandMore } from 'react-icons/md'
import styled from 'styled-components'
import { Footer } from '../Footer'
import { sendGtagEvent } from '../GA/helpers'

export const RouteFAQ = () => {
  const [openPanel, setOpenPanel] = useState(null)

  const togglePanel = (panelId, question) => {
    if (openPanel !== panelId) {
      setOpenPanel(panelId)
    } else {
      setOpenPanel(null)
    }

    sendGtagEvent(
      'FAQ question toggle',
      'faq',
      question,
      openPanel !== panelId ? 1 : 0
    )
  }

  return (
    <>
      <Header />
      <Section>
        <SectionContent>
          <SectionHeading>Najczęściej zadawane pytania</SectionHeading>
          {questionsGroups.map((questionsGroup, i) => (
            <Box mt={4} key={questionsGroup.groupName}>
              <SectionSubHeading>{questionsGroup.groupName}</SectionSubHeading>
              {questionsGroup.questions.map((question, ii) => (
                <Accordion
                  key={ii}
                  expanded={openPanel === `${i}-${ii}`}
                  onChange={() => togglePanel(`${i}-${ii}`, question.q)}
                >
                  <AccordionSummary expandIcon={<MdExpandMore />}>
                    <strong>{question.q}</strong>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box mx={2}>{question.a}</Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ))}
          <SectionHeading>Masz więcej pytań?</SectionHeading>
          <Box component={SectionSubHeading} display="flex" alignItems="center">
            Napisz do nas&nbsp;
            <a onClick={() => window.FB.CustomerChat.showDialog()}>
              <Box display="flex" alignItems="center">
                na czacie&nbsp;
                <MessengerIcon size={24} />
              </Box>
            </a>
          </Box>
        </SectionContent>
      </Section>
      <Footer />
    </>
  )
}

const MessengerIcon = styled(FaFacebookMessenger)`
  display: block;
`
