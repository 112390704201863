import { LogoInline } from '../LogoInline'
import React from 'react'

export const questionsGroups = [
  {
    groupName: 'Funkcje',
    questions: [
      {
        q: 'Czym jest elektroniczna książka pobytu na strzelnicy?',
        a: (
          <>
            Elektroniczna książka pobytu na strzelnicy jest niczym innym, jak
            cyfrową wersją książki, którą musi posiadać każda strzelnica. Jednak
            w porównaniu do tradycyjnej książki, system <LogoInline /> wyróżnia
            szereg zalet:
            <ul>
              <li>łatwa i szybka obsługa</li>
              <li>
                bezpieczeństwo danych - nikt nie ma wglądu do danych osób, które
                wpisały się przed nimi
              </li>
              <li>
                kontrola - monitorowanie wpisów, podgląd na żywo, przegląd
                wpisów z poprzednich dni, eksport danych
              </li>
              <li>
                wygoda - brak konieczności ponownego wpisywania swoich danych
                przy każdej wizycie
              </li>
            </ul>
          </>
        )
      },
      {
        q: 'Do czego służy aplikacja mobilna CelPal?',
        a: (
          <>
            Aplikację mobilną może pobrać każdy. Jest dostępna dla systemów
            Android oraz iOS za darmo.
            <br />
            Dzięki aplikacji mobilnej goście strzelnicy nie muszą podawać swoich
            danych przy każdej wizycie. Wystarczy, że raz wypełnią je wewnątrz
            aplikacji i od tej pory przy każdej wizycie składają tylko podpis.
          </>
        )
      },
      {
        q: 'Czy każdy gość na strzelnicy musi zakładać konto CelPal?',
        a: (
          <>
            Nie ma takiej potrzeby. Jeśli uruchomią Państwo tablet, który będzie
            dostępny dla gości strzelnicy, każdy będzie mógł wpisać się do
            książki podając swoje dane.
            <br />
            <br />
            Jednak pobranie aplikacji mobilnej i założenie konta w systemie
            niesie za sobą szereg korzyści, z których najistotniejszą jest brak
            konieczności podawania danych przy każdej wizycie. To rozwiązanie
            jest idealne dla klientów, którzy regularnie odwiedzają strzelnicę.
          </>
        )
      },
      {
        q: 'Czy muszę posiadać osobne konto dla każdej aplikacji?',
        a: (
          <>
            Nie. System <LogoInline /> jest oparty na centralnym API
            (Application programming interface), z którego korzysta każda z
            aplikacji. Po zarejestrowaniu konta np. poprzez aplikację mobilną
            użytkownik może używać tych samych danych do logowania we wszystkich
            aplikacjach z rodziny <LogoInline /> (panel administracyjny,
            elektroniczna książka, aplikacja mobilna itd.).
          </>
        )
      }
    ]
  },
  {
    groupName: 'Dane i bezpieczeństwo',
    questions: [
      {
        q: 'Czy dane w elektronicznej książce są chronione?',
        a: (
          <>
            Tak. To podstawa egzystencji naszego systemu. Każdy podmiot
            przetwarzający dane osobowe, musi dołożyć wszelkich starań, żeby
            chronić dane swoich klientów. Urząd ochrony danych osobowych
            przewiduje ogromne kary pieniężne za niedopilnowanie obowiązku
            ochrony danych osobowych. Ochrona danych w papierowej książce pobytu
            praktycznie nie istnieje, ponieważ dane te nie są w żaden sposób
            zabezpieczone. Nasz produkt zdejmuje z Państwa uciążliwy obowiązek
            bezpiecznego przechowywania danych.
          </>
        )
      },
      {
        q: 'Czy mogę przechowywać dane klientów, jeśli nie wyrażą na to zgody?',
        a: (
          <>
            Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
            27 kwietnia 2016 r. w sprawie ochrony osób fizycznych (RODO) określa
            podstawy przetwarzania danych osobowych. Jedną z nich jest zgoda
            klienta. Jednak nie jest to jedyna podstawa. Jedną z istotnych
            podstaw może być wymóg wynikający z przepisów prawa. Na strzelnicy
            takim przepisem jest Rozporzadzenie Ministra Spraw Wewnętrznych i
            Administracji z dnia 15 marca 2000 r. w sprawie wzorcowego
            regulaminu strzelnic, zatem każdy klient, który chce korzystać ze
            strzelnicy, musi podać swoje dane i nie jest to uwarunkowane jego
            dodatkową zgodą.
          </>
        )
      },
      {
        q: 'Jakie dane są przechowywane w systemie?',
        a: (
          <>
            W systemie <LogoInline /> przechowujemy dane niezbędne do utworzenia
            wpisu w elektronicznej książce pobytu na strzelnicy, tj. imię,
            nazwisko oraz adres zamieszkania lub numer pozwolenia i nazwę
            organu, który je wydał.
            <br />
            Oprócz tego zapisujemy odręczny podpis wykonany na ekranie
            urządzenia oraz datę dokonania wpisu.
          </>
        )
      },
      {
        q:
          'Czy jako właściciel strzelnicy, mogę mieć wgląd w bieżące wpisy do książki?',
        a: (
          <>
            Oczywiście. Podgląd wpisów na żywo to jedna z gównych funkcji
            naszego systemu.
            {/* TODO: tu dać filmik z pokazem bieżących wpisów i ich odświeżania */}
            <br />
            Jednak dodatkowo należy zaznaczyć, że obowiązek prowadzenia książki
            pobytu na strzelnicy spoczywa na prowadzącym strzelanie, a nie na
            właścicielu strzelnicy. Dlatego, warto pamiętać o stosownej umowie
            dotyczącej przetwarzania danych osobowych między strzelnicą, a
            prowadzącym strzelanie.
          </>
        )
      },
      {
        q: <>Gdzie są przechowywane dane w systemie CelPal?</>,
        a: (
          <>
            Dane są przechowywane na profesjonalnych serwerach typu Cloud. To
            sprawdzona forma prowadzenia infrastruktury systemu informatycznego.
            Tzw. chmura zapewnia bezpieczeństwo w warstwie serwerowej,
            nieprzerwany dostęp, dużą wydajność oraz elastyczność w dostarczaniu
            nowych wersji oprogramowania.
          </>
        )
      },
      {
        q: 'Czy dane w elektronicznej książce są bezpieczne?',
        a: (
          <>
            Tak. Korzystamy ze sprawdzonych rozwiązań technologicznych oraz
            spełniamy wymagania bezpieczeństwa projektu OWASP-10. Dokładamy
            wszelkich starań, żeby nasz system był odporny na wszelkie próby
            nieautoryzowanego pozyskania danych.
          </>
        )
      },
      {
        q:
          'Kto jest administratorem danych osobowych użytkowników elektronicznej książki?',
        a: (
          <>
            W momencie dokonania wpisu do elektronicznej książki <LogoInline />{' '}
            użytkownik powierza swoje dane osobowe prowadzącemu strzelanie na
            strzelnicy do której się wpisuje, dlatego to Państwo jako nasz
            klient są administratorem danych osobowych.
            <br />
            <br />
            Oprócz umowy licencyjnej podpisujemy z naszymi klientami umowę
            powierzenia przetwarzania danych osobowych, która kwalifikuje nasz
            podmiot jako "procesora" danych osobowych.
          </>
        )
      },
      {
        q: 'Czy podpis na ekranie urządzenia mobilnego jest bezpieczny?',
        a: (
          <>
            Tak. Rozporzadzenie Ministra Spraw Wewnętrznych i Administracji z
            dnia 15 marca 2000 r. w sprawie wzorcowego regulaminu strzelnic
            przewiduje, że osoba korzystająca ze strzelnicy potwierdzi
            zapoznanie się z treścią regulaminu <i>własnoręcznym podpisem</i>.
            <br />
            Pojęcie <i>własnoręcznego podpisu</i> nie wyklucza utrwalenia go na
            innym nośniku niż kartka papieru, dlatego podpis na ekranie
            dotykowym może służyć jako oświadczenie woli.
            <br />
            <br />
            Do rejestracji podpisu w naszym systemie wykorzystujemy następujące
            informacje:
            <ul>
              <li>współrzędne punktów nacisku</li>
              <li>precyzyjny czas nałożenia każdego punktu</li>
              <li>prędkość ruchu (wyliczana z czasu wykonania nacisku)</li>
              <li>
                siła nacisku (jeśli obsługiwane urządzenie przekazuje taką
                informację)
              </li>
            </ul>
          </>
        )
      }
    ]
  }
]
