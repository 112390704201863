import React from 'react'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Pricing } from '../Pricing'

export const RoutePricing = () => {
  return (
    <>
      <Header />
      <Pricing />
      <Footer />
    </>
  )
}
