import styled from 'styled-components'

export const SectionContent = styled.div`
  padding: 0 24px;
  max-width: 960px;
  margin: 40px auto;
  flex-grow: 1;
  
  @media(min-width: 1248px) {
    padding: 0;
  }
`
