import React, { useEffect } from 'react'
import { BrowserRouter, Switch, useLocation } from 'react-router-dom'
import { Home } from './components/RouteHome'
import { Route } from 'react-router'
import { RoutePricing } from './components/RoutePricing'
import { Safety } from './components/RouteSafety'
import { BaseStyles } from './components/BaseStyles'
import { Product } from './components/RouteHome/Product'
import { Contact } from './components/Contact/Contact'
import { CookieBanner } from './components/CookiesBanner'
import { UI } from './components/RouteUI'
import { PrivacyPolicy } from './components/PrivacyPolicy'
import { NotFound } from './components/NotFound'
import { MuiThemeProvider } from '@material-ui/core'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import { TermsOfUse, TermsOfUseMobileApp } from './components/TermsOfUse'
import { Law } from './components/RouteLaw'
import { RouteFAQ } from './components/RouteFAQ'

const muiTheme = createMuiTheme({
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1600
    }
  }
})

export function App() {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <BrowserRouter>
        <BaseStyles />
        <Routes />
        <CookieBanner />
      </BrowserRouter>
    </MuiThemeProvider>
  )
}

const Routes = () => {
  const location = useLocation()

  useEffect(() => {
    if (
      typeof window.gtag === 'function' &&
      process.env.NODE_ENV === 'production'
    ) {
      window.gtag('config', process.env.REACT_APP_GA_ID, {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname
      })
    }
  }, [location])

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/bezpieczenstwo" component={Safety} />
      <Route path="/prawo" component={Law} />
      <Route path="/ui" component={UI} />
      <Route path="/cennik" component={RoutePricing} />
      <Route path="/produkt" component={Product} />
      <Route path="/kontakt" component={Contact} />
      <Route path="/pytania" component={RouteFAQ} />
      <Route path="/regulamin" component={TermsOfUse} />

      {/* TODO: add overview "Documents" route for listing all policies */}
      <Route path="/mobilna-polityka-prywatnosci" component={PrivacyPolicy} />
      <Route
        path="/regulamin-aplikacji-mobilnej"
        component={TermsOfUseMobileApp}
      />

      <Route component={NotFound} />
    </Switch>
  )
}
