import React from 'react'
import { Section } from './Section'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { SectionContent } from './SectionContent'

export const PageSkeleton = ({ children }) => {
  return (
    <Section>
      <Header />
      <SectionContent>{children}</SectionContent>
      <Footer />
    </Section>
  )
}
