import React, { useEffect, useRef } from 'react'
import { Section, SectionContent, SectionText } from '../Section'
import { LogoInline } from '../LogoInline'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const WelcomeOverview = ({ shouldScroll }) => {
  const ref = useRef()

  useEffect(() => {
    if (ref.current && shouldScroll) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [shouldScroll])

  return (
    <Section ref={ref}>
      <WelcomeSection>
        <SectionText large>
          <BiggerLogoInline /> to marka stworzona specjalnie dla{' '}
          <strong>strzelnic</strong> i <strong>klubów strzeleckich</strong>.
          Naszą misją jest wspieranie strzelców przy pomocy rozwiązań
          informatycznych. Kładziemy nacisk przede wszystkim na{' '}
          <FeatureLink to="/bezpieczenstwo">bezpieczeństwo danych</FeatureLink> i{' '}
          <FeatureLink to="/ui">prostotę w użytkowaniu naszych produktów.</FeatureLink>
        </SectionText>
      </WelcomeSection>
    </Section>
  )
}

const FeatureLink = styled(Link)`
  font-weight: bold;
`

const WelcomeSection = styled(SectionContent)`
  margin-top: 80px;
  margin-bottom: 80px;
`

const BiggerLogoInline = styled(LogoInline)`
  height: 2em;
  vertical-align: bottom;
`