import React from 'react'
import { PageSkeleton } from '../Section/PageSkeleton'
import { SectionHeading } from '../Section'
import { LogoInline } from '../LogoInline'
import { Box } from '@material-ui/core'
import { Content } from './TermsContent'

export function TermsOfUse() {
  return (
    <PageSkeleton>
      <SectionHeading>
        Regulamin korzystania z licencji <LogoInline /> i korzystania z usług
        świadczonych drogą elektroniczną.
      </SectionHeading>
      <Box display="flex" justifyContent="center">
        <Content>
          <ol>
            <li>
              Postanowienia ogólne, sposób zawarcia umowy
              <ol>
                <li>
                  Przedmiotem niniejszego Regulaminu jest ustalenie zasad i
                  warunków udzielania licencji na korzystanie z Oprogramowania
                  lub Oprogramowania Dodatkowego umieszczonego w Serwisach w
                  domenach: www.celpal.net, admin.celpal.net, portal.celpal.net,
                  book.celpal.net oraz określenie rodzaju i warunku usług
                  świadczonych drogą elektroniczną polegającą na udostępnieniu
                  dostępu do programu stanowiącego odpowiednik elektronicznej
                  Książki Strzelnicy w rozumieniu rozporządzenia Ministra Spraw
                  Wewnętrznych i Administracji z dnia 15 marca 2000 r. w sprawie
                  wzorcowego regulaminu strzelnic.
                </li>
                <li>
                  Regulamin ma zastosowanie do Oprogramowania lub Oprogramowania
                  Dodatkowego, do którego autorskie prawa majątkowe przysługują
                  spółce Kodeusz sp. z o.o. z siedzibą przy ul. Jana Heweliusza
                  11/819 80-890 Gdańsk, zwanym dalej: Licencjodawcą
                </li>
                <li>
                  O ile Strony nie postanowią zgodnie inaczej, Umowa jest
                  zawarta na podstawie Regulaminu, co oznacza, że określa on ich
                  prawa i obowiązki. Jeżeli Strony zawrą również Umowę, która
                  będzie obejmować postanowienia sprzeczne z postanowieniami
                  Regulaminu, stosuje się - w zakresie objętym taką kolizją -
                  postanowienia umowne; w pozostałym zakresie Regulamin nadal
                  wiąże Strony.
                </li>
                <li>
                  Zawarcie Umowy może nastąpić w szczególności w wyniku:
                  <ol>
                    <li>
                      podpisania przez Strony dokumentu lub dokumentów o nazwie
                      „umowa” lub o analogicznej nazwie
                    </li>
                    <li>
                      poprzez przyjęcie oferty usług Licencjodawcy przez
                      podmiot, któremu Licencjodawca ją złożył, jak również w
                      drodze złożenia zamówienia przez ten podmiot. Podmiot ten,
                      przyjmując ofertę lub składając zamówienie, oświadcza tym
                      samym, że zapoznał się przed jej przyjęciem z treścią
                      Regulaminu Licencjodawcy i akceptuje go w całości bez
                      zastrzeżeń, w szczególności zaś godzi się na to, by
                      określał on prawa i obowiązki jako Licencjobiorcy i
                      Licencjodawcy, jak również oświadcza, iż traktuje go jako
                      wystarczają-ce poinformowanie o zasadach korzystania z
                      usług świadczonych drogą elektroniczną przez
                      Licencjodawcę.
                    </li>
                    <li>
                      W przypadku określonym w pkt 1.4 powyżej: a) lit. „a” –
                      umowa lub inny dokument tamże wskazany oraz Regulamin; b)
                      lit. „b” - przyjęta oferta, oraz Regulamin - kształtują
                      prawa i obowiązki Stron w zakresie nimi określonymi,
                      składając się łącznie na treść Umowy.
                    </li>
                  </ol>
                </li>
                <li>
                  Umowa określa w szczególności:
                  <ol>
                    <li>
                      a) przedmiot; jeżeli Strony nie podpisały dokumentu o
                      nazwie umowa lub o analogicznej nazwie - faktura VAT
                      wystawiona przez Licencjodawcę decyduje o przedmiocie
                      Umowy i wysokości ceny licencji
                    </li>
                    <li>
                      b) liczbę licencji objętych Umową, a jeżeli nie wskazano
                      ich liczby to przyjmuje się, że Umowa obejmuje jedną
                      licencję i jeden jednoczesny dostęp dla jednego
                      Licencjobiorcy
                    </li>
                    <li>
                      c) charakter udzielonej licencji, a jeżeli nie została
                      określona to przyjmuje się, że Licencjodawca udziela
                      licencji niezbywalnej, niewyłącznej i nieprzenoszalnej
                    </li>
                    <li>
                      d) okres i wynagrodzenie Licencjodawcy za dany okres, a
                      jeżeli nie wskazano okresu i wynagrodzenia to przyjmuje
                      się, że określa je Regulamin i cennik widoczny na stronie
                      www.celpal.net/cennik.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Definicje
              <ol>
                <li>
                  Serwisy - serwisy internetowe należące do Licencjodawcy,
                  umieszczone w domenach: www.celpal.net, admin.celpal.net,
                  portal.celpal.net, book.celpal.net.
                </li>
                <li>
                  Utwory - wszelkie zamieszczone w Serwisach materiały wraz z
                  ich integralną częścią i warstwą ilustracyjną, obejmującą
                  m.in. grafiki, fragmenty kodu, w tym stanowiące bazę danych w
                  rozumieniu Ustawy z 27.07.2001 r. O ochronie baz danych, które
                  spełniają cechy utworu opisane w Ustawie z dnia 4 lutego 1994
                  r. o prawie autorskim i prawach pokrewnych
                </li>
                <li>
                  Licencja - odpłatna, niezbywalna, niewyłączna licencja na
                  korzystanie z Oprogramowania lub Oprogramowania Dodatkowego,
                  udzielana Licencjobiorcy przez Licencjodawcę na podstawie
                  Umowy oraz Regulaminu
                </li>
                <li>
                  Licencjobiorca - podmiot nie będący konsumentem w rozumieniu
                  art. 22 ze zn. 1 Kodeksu Cywilnego, osoba prawna lub jednostka
                  organizacyjna nie posiadająca osobowości prawnej, korzystająca
                  w Oprogramowania lub Oprogramowania Dodatkowego na podstawie
                  Umowy oraz Regulaminu
                </li>
                <li>Licencjodawca - Kodeusz sp. z o.o.</li>
                <li>
                  Opłata licencyjna - wynagrodzenie Licencjodawcy należne na
                  podstawie Umowy
                </li>
                <li>
                  Oprogramowanie, Program – chroniony prawem autorskim program
                  komputerowy pod nazwą “CelPal” dostępny w Pakiecie usług, w
                  najnowszej dostępnej wersji, wraz z pozostałymi elementami
                  niebędącymi programem komputerowym w rozumieniu Prawa
                  autorskiego, ale stanowiącymi integralną część Oprogramowania,
                  w tym jego materiały graficzne, służący do:
                  <ol>
                    <li>
                      obsługi elektronicznej książki rejestru pobytu na
                      strzelnicy tj. Książki Rejestru Pobytu na Strzelnicy, o
                      której mowa w R. 1 pkt 1 ppkt 3 w rozporządzeniu Ministra
                      Spraw Wewnętrznych i Administracji z dnia 15 marca 2000 r.
                      w sprawie wzorcowego regulaminu strzelnic;
                    </li>
                    <li>
                      wykonywania pozostałych funkcji Oprogramowania,
                      szczegółowo i wyłącznie opisanych na stronie
                      https://celpal.net/produkt.
                    </li>
                  </ol>
                </li>
                <li>
                  Umowa Licencyjna, Umowa - umowa licencyjna o korzystanie z
                  Oprogramowanie lub Oprogramowania Dodatkowego, zawarta
                  pomiędzy Licencjodawcą a Licencjobiorcą,
                </li>
                <li>
                  Aktualizacja – kolejne, nowsze wersje Oprogramowania lub
                  Oprogramowania Dodatkowego,
                </li>
                <li>
                  Pakiet usług, Pakiet – zestaw usług stanowiący całościową
                  ofertę wybraną przez Zleceniobiorcę spośród dostępnych: Pakiet
                  Premium, Pakiet Podstawowy lub Pakiet Minimum. Szczegóły
                  każdego z Pakietów usług określa Cennik oraz niniejszy
                  Regulamin.
                </li>
                <li>
                  Usługi Dodatkowe - niezależne od Pakietu Premium, Pakietu
                  Podstawowego i Pakietu Minimum usługi określone w cenniku
                  https://celpal.net/cennik oraz w niniejszym Regulaminie.
                </li>
                <li>
                  Cennik – cennik Pakietów i Usług dodatkowych dostępny pod
                  adresem https://celpal.net/cennik
                </li>
                <li>
                  Wymagania techniczne - wymagania konieczne dla prawidłowego
                  funkcjonowania Oprogramowania i Oprogramowania Dodatkowego
                  określone przez Licencjodawcę:
                  <ol>
                    <li>
                      Sprzęt wyposażony w ekran dotykowy umożliwiający dokonanie
                      własnoręcznego podpisu
                    </li>
                    <li>
                      Sprzęt wyposażony w przeglądarkę internetową Google Chrome
                      w wersji 81 lub nowszej
                    </li>
                    <li>Sprzęt posiadający dostęp do internetu</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              Ogólne zasady licencji i korzystania z usług
              <ol>
                <li>
                  Licencja na korzystanie z Oprogramowania lub Oprogramowania
                  Dodatkowego udzielona jest na okres 12 miesięcy. Po upływie
                  tego okresu Licencja ulega przedłużeniu na czas nieokreślony,
                  chyba że którakolwiek ze Stron Umowy nie później niż 14 dni
                  przed wygaśnięciem licencji złoży drugiej Stronie oświadczenie
                  o chęci zakończenia współpracy.
                </li>
                <li>
                  Licencja na korzystanie z Oprogramowania lub Oprogramowania
                  Dodatkowego ma charakter odpłatny, niewyłączny i niezbywalny i
                  udzielona jest na czas oznaczony, który ulega zmianom zgodnie
                  z postanowienia Umowy oraz Regulaminu.
                </li>
                <li>
                  Licencjobiorca może korzystać z Oprogramowania lub
                  Oprogramowania Dodatkowego w zakresie i w warunkach
                  określonych w Regulaminie i Umowie Licencyjnej z
                  uwzględnieniem przepisów Ustawy z dnia 4 lutego 1994 r. O
                  prawie autorskim i prawach pokrewnych.
                </li>
                <li>
                  Licencja nie obejmuje prawa do wykonywania zależnego prawa
                  autorskiego do Oprogramowania lub Oprogramowania Dodatkowego
                  ani nie obejmuje jakichkolwiek praw do znaków towarowych
                  należących do Kodeusz sp. z o.o.
                </li>
                <li>
                  Licencjobiorca nie jest uprawniony do dokonywania
                  jakichkolwiek zmian w Oprogramowaniu lub Oprogramowaniu
                  Dodatkowym. Przez „jakiekolwiek” rozumie się nie objęte
                  zakresem dozwolonego użytku korzystanie z Oprogramowania lub
                  Oprogramowania Dodatkowego mające charakter materialny,
                  zarobkowy lub podobny, możliwy do odbycia wyłącznie po
                  wniesieniu opłaty licencyjnej a także pod warunkiem zawarcia
                  Umowy licencyjnej.
                </li>
                <li>
                  Jeżeli Strony nie określiły wynagrodzenia Licencjodawcy w
                  innej wysokości, to oznacza to, iż Licencjobiorca godzi się na
                  zastosowanie przez Licencjodawcę wynagrodzenia wynikającego z
                  cennika obowiązującego u Licencjodawcy w dacie zawarcia Umowy,
                  widniejącego na stronie internetowej www.celpal.net.
                </li>
                <li>
                  Licencjobiorca winien uiścić Licencjodawcy wynagrodzenie w
                  wysokości ceny pakietu. Cena ta winna zostać uiszczona z góry
                  w terminie podanym na fakturze VAT wystawionej przez
                  Licencjodawcę, z tym że termin płatności wówczas nie może być
                  krótszy niż 14 (czternaście) dni od dnia wystawienia faktury
                  VAT. Wynagrodzenie jest płatne przelewem na rachunek bankowy
                  Licencjodawcy. Doręczenie faktury VAT Licencjobiorcy jest
                  jednoznaczne, nawet w braku stosownej formuły wezwania, z
                  wezwaniem go do zapłaty kwoty, na którą opiewa w terminie
                  wskazanym w jej treści.
                </li>
                <li>
                  W przypadku opóźnienia Licencjobiorcy w płatności ceny, także
                  gdy opóźnienie dotyczy płatności danej raty lub części
                  wynagrodzenia, Licencjodawca ma - w każdej takiej sytuacji -
                  prawo wstrzymać się z realizacją swoich świadczeń na rzecz
                  Licencjobiorcy, bez konieczności wezwania Licencjobiorcy do
                  jej zapłaty, zarówno w zakresie Usługi podstawowej, jak i
                  świadczeń pomocniczych. Licencjodawca ma prawo uniemożliwić
                  dostęp do przedmiotu Umowy aż do dnia uiszczenia w całości
                  zaległego wynagrodzenia wraz z odsetkami ustawowymi od dnia
                  wymagalności do dnia zapłaty, z uwzględnieniem pkt 3.9
                  poniżej. Licencjobiorca odzyska dostęp do przedmiot Umowy - na
                  zasadach określonych w Regulaminie - jeżeli uiści zaległe
                  wynagrodzenie wraz z ww. odsetkami w okresie abonamentowym,
                  tylko jednak do końca tego okresu. Zastrzega się jednocześnie,
                  że we wszystkich podanych w niniejszym Regulaminie, w tym
                  określonych wyżej przypadkach, Licencjodawcy jest należne
                  wynagrodzenie całkowite za cały okres obowiązywania Umowy,
                  także, gdy przez część tego okresu, z przyczyn wyżej
                  określonych lub z przyczyn opisanych wyżej w Regulaminie
                  Licencjobiorca nie mógł korzystać z przedmiotu Umowy.
                </li>
                <li>
                  Jeżeli w drodze indywidualnych uzgodnień zapłata ceny zostanie
                  rozłożona na raty lub części, Strony przyjmują, iż w przypadku
                  opóźnienia w zapłacie danej odpowiednio raty lub części, z
                  upływem terminu jej płatności, pozostałe odpowiednio raty lub
                  części stają się natychmiast wymagalne. Licencjodawca nie jest
                  zobowiązany do informowania Licencjobiorcy co do wystąpienia
                  skutku, o którym wyżej mowa.
                </li>
                <li>
                  Warunkiem korzystania z usługi jest terminowe opłacenie Opłat
                  licencyjnych oraz spełnienie przez Licencjobiorcę Wymagań
                  Technicznych.
                </li>
              </ol>
            </li>
            <li>
              Pakiety korzystania
              <ol>
                <li>
                  Poprzez zawarcie Umowy Licencjobiorca otrzymuje dostęp do
                  jednego z Pakietów usług oferowanych przez Licencjodawcę.
                  Szczegóły, właściwości, zawartość oraz cena pakietu są zawsze
                  określone w Cenniku pod adresem https://celpal.net/cennik.
                </li>
                <li>
                  W wypadku gdy właściwości wybranego Pakietu wymagają dalszego
                  dostosowania do potrzeb Licencjobiorcy istnieje możliwość
                  zlecenia w Umowie dodatkowej usługi o korzystanie z wybranego
                  Oprogramowania Dodatkowego z produktów Licencjodawcy. Należą
                  do nich: usługa dodatkowej strzelnicy, usługa rejestru
                  klubowiczów, usługa kart członkowskich oraz usługa migracji
                  danych klubu strzeleckiego.
                </li>
                <li>
                  Szczegóły, właściwości, zawartość oraz ceny ww. usług
                  Oprogramowania Dodatkowego zleconego jako osobna usługa są
                  zawsze określone w Cenniku https://celpal.net/cennik i nie
                  zawierają się w podstawowej opłacie licencyjnej za Pakiet
                  usług.
                </li>
              </ol>
            </li>
            <li>
              Obowiązki i oświadczenia Licencjodawcy
              <ol>
                <li>
                  Do obowiązków Licencjodawcy należy usługa bieżącej pomocy
                  technicznej w postaci:
                  <ol>
                    <li>
                      dostosowanie Oprogramowania i Oprogramowania Dodatkowego
                      do obowiązujących przepisów prawa,
                    </li>
                    <li>
                      usuwanie istotnych wad i nieprawidłowości w działaniu
                      Oprogramowania, Oprogramowania Dodatkowego i Aktualizacji,
                      przy czym nie dotyczy to wad powstałych na skutek działań
                      Licencjobiorcy
                    </li>
                    <li>
                      usuwanie awarii Oprogramowania, Oprogramowania Dodatkowego
                      lub Aktualizacji,
                    </li>
                    <li>
                      nadzór nad stanem danych i Oprogramowania, Oprogramowania
                      Dodatkowego lub Aktualizacji po usunięciu wad lub awarii,
                    </li>
                    <li>
                      prowadzenie integralności bazy danych oprogramowania oraz
                      zapewnienie poprawności jej administrowania;
                    </li>
                    <li>
                      Licencjodawca zobowiązany jest do dokonywania Aktualizacji
                      do Oprogramowania lub Oprogramowania Dodatkowego przez
                      okres trwania Umowy i wykonania usługi obsługi technicznej
                      Oprogramowania i Aktualizacji przez okres trwania Umowy.
                    </li>
                  </ol>
                </li>
                <li>
                  Licencjodawca oświadcza, że przysługuje mu nieograniczone i
                  wyłączne prawo autorskie do Oprogramowania i Oprogramowania
                  dodatkowego oraz jest podmiotem uprawnionym do udzielania
                  licencji i dostawy Oprogramowania i Oprogramowania
                  Dodatkowego. Ponadto Licencjodawca oświadcza, że
                  Oprogramowanie i Oprogramowanie Dodatkowe dostarczone
                  Licencjobiorcy jest wolne od wad prawnych i fizycznych oraz,
                  że jest zgodne z zaleceniami, normami i obowiązującymi
                  wymaganiami techniczno-eksploatacyjnymi na terenie
                  Rzeczpospolitej Polskiej. Oprogramowanie i Oprogramowanie
                  Dodatkowe, również jako utwór w rozumieniu prawa autorskiego
                  jest wolne od wad prawnych oraz stanowi dzieło oryginalne,
                  spełniające kryteria wskazane w treści art. 1 Prawa
                  autorskiego. Licencjodawcy przysługują także autorskie prawa
                  majątkowe w zakresie niezbędnym dla realizacji zobowiązań
                  przyjętych w Umowie Licencyjnej.
                </li>
                <li>
                  Licencjodawca nie ponosi odpowiedzialności za Oprogramowanie i
                  Oprogramowanie dodatkowe w zakresie:
                  <ol>
                    <li>
                      Błędów w funkcjonowaniu spowodowanych wadliwym działaniem
                      oprogramowania innego niż Oprogramowanie i Oprogramowanie
                      Dodatkowe Licencjodawcy tj. oprogramowanie typu third
                      party application lub sprzętu informatycznego lub sprzętu
                      mobilnego Licencjobiorcy, w tym za szkody spowodowane
                      wadliwością takich aplikacji i sprzętu;
                    </li>
                    <li>
                      błędy w funkcjonowaniu spowodowane działaniem tzw.
                      złośliwego oprogramowania;
                    </li>
                    <li>
                      zawartości jakichkolwiek danych, w tym danych osobowych i
                      administracyjnych oraz finansowych, zapisywanych za pomocą
                      Programu i Oprogramowanie dodatkowe przez osoby używające
                      Program i Oprogramowanie dodatkowe;
                    </li>
                    <li>
                      zawartości wyciągów, wydruków, zestawień danych tworzonych
                      za pomocą programu;
                    </li>
                    <li>
                      skutki naruszenia przez Licencjobiorcę obowiązujących
                      przepisów prawa, jak również nieuwzględnienie zasad
                      użytkowania Programu;
                    </li>
                    <li>
                      za jakiekolwiek szkody bezpośrednie i pośrednie
                      (obejmujące zwłaszcza utracone korzyści oraz szkody
                      wynikłe z zakłócenia działalności, utratę informacji
                      gospodarczych, utratę danych lub inne straty) wynikłe z
                      zawarcia niniejszej Umowy lub będące następstwem używania
                      albo niemożności używania Oprogramowania i Oprogramowania
                      Dodatkowego bądź będące następstwem świadczenia usługi
                      Serwisu lub jej braku.
                    </li>
                    <li>
                      za wady i błędy w Oprogramowaniu i Oprogramowaniu
                      Dodatkowym oraz ich skutki powstałe w rezultacie
                      nieuprawnionego (bez uzyskania licencji lub po upływie
                      okresu licencyjnego), nieautoryzowanego (wskutek
                      niewprowadzenia lub błędnego wprowadzenia kodu dostępu,
                      loginu lub hasła dostępowego) lub nieprawidłowego
                      (niezgodnego z przeznaczeniem Oprogramowaniem lub z
                      instrukcjami) lub korzystania z Oprogramowania przez
                      Licencjobiorcę, jego personel lub osoby trzecie.
                    </li>
                  </ol>
                </li>
                <li>
                  W każdym przypadku jednak całkowita odpowiedzialność
                  Licencjodawcy na podstawie Umowy Licencyjnej jest ograniczona
                  do kwoty rzeczywiście zapłaconej przez Licencjobiorcę za
                  licencję.
                </li>
                <li>
                  Na podstawie odrębnej Umowy o powierzenie przetwarzania danych
                  osobowych Licencjodawca staje się Podmiotem Przetwarzającym
                  dane osobowe powierzone przez Licencjodawcę będącego
                  Administratorem tych danych. Licencjodawca dołoży wszelkich
                  starań aby chronić dane osobowe powierzone przez
                  Licencjobiorcę przed nieuprawnionym dostępem osób trzecich i
                  będzie je przetwarzać w celu realizacji wypełnienia
                  usprawiedliwionych celów Licencjobiorcy jako Administratora
                  danych.
                </li>
              </ol>
            </li>
            <li>
              Obowiązki i oświadczenia Licencjobiorcy
              <ol>
                <li>
                  Licencjobiorca udzieli Licencjodawcy niezbędnej pomocy i
                  wszelkich informacji potrzebnych do wykonywania prac
                  wdrożeniowych niezbędnych do korzystania Oprogramowania i
                  Oprogramowania dodatkowego. Jednocześnie Licencjobiorca
                  zwalnia Licencjodawcę z odpowiedzialności za brak
                  funkcjonalności Oprogramowania na konkretnym urządzeniu
                  Licencjobiorcy, w tym w szczególności wynikającego z zakłóceń
                  i przerw funkcjonalności Oprogramowania wywołanych różnicami
                  sprzętowymi, ustawieniami połączeń internetowych albo
                  personalizacją środowiska systemowego i programistycznego
                  Licencjobiorcy.
                </li>
                <li>
                  Licencjobiorca zobowiązuje się do zainstalowania i korzystania
                  z Oprogramowania lub Oprogramowania Dodatkowego wyłącznie na
                  sprzęcie spełniającym aktualne Wymagania Techniczne.
                </li>
                <li>
                  Licencjobiorca uzyska dostęp do Oprogramowania lub
                  Oprogramowania Dodatkowego za pośrednictwem strony
                  https://admin.celpal.net, https://book.celpal.net oraz
                  https://portal.celpal.net. Licencjobiorca zobowiązuje się do
                  świadczenia osobistego zaangażowania w przeszkolenie nowych
                  użytkowników korzystających z systemu oraz do uposażenia ich
                  samodzielnie w loginy oraz hasła.
                </li>
                <li>
                  Licencjobiorca zobowiązuje się powstrzymać od:
                  <ol>
                    <li>
                      rozprowadzania, kopiowania, wynajmowania, publikowania,
                      modyfikowania, poprawiania, adaptowania ani tłumaczenia
                      aplikacji lub udzielania na nią sublicencji;
                    </li>
                    <li>
                      odtwarzania kodu źródłowego, dekompilowania lub
                      dezasemblowania aplikacji, tworzenia na jej podstawie
                      dzieł pochodnych;
                    </li>
                    <li>
                      podejmowania prób uzyskania kodu źródłowego aplikacji na
                      podstawie kodu skompilowanego;
                    </li>
                    <li>
                      użytkowania aplikacji w połączeniu z nieautoryzowanym,
                      nielegalnym, fałszywym lub modyfikowanym sprzętem albo
                      oprogramowaniem;
                    </li>
                    <li>instalowania wcześniejszych wersji aplikacji;</li>
                    <li>
                      naruszania przepisów, rozporządzeń, ustaw lub praw Kodeusz
                      sp. z o. o. albo stron trzecich w związku z dostępem do
                      lub korzystaniem z aplikacji przez użytkownika;
                    </li>
                    <li>
                      pozyskiwania aplikacji w sposób inny niż poprzez
                      autoryzowane metody dystrybucji; ani wykorzystywania
                      aplikacji w sposób inny niż zgodny z licencją.
                    </li>
                  </ol>
                </li>
                <li>
                  Do pozostałych elementów Oprogramowania i Oprogramowania
                  Dodatkowego, niebędących jednocześnie programem komputerowym,
                  stosuje się przepisy Prawa Autorskiego dotyczące utworów lub
                  przepisy Prawa Własności Przemysłowej. Licencjodawca udziela
                  na zasadach i w ramach wynagrodzenia dla licencji udzielonej
                  na Oprogramowanie, licencji na pozostałe elementy
                  Oprogramowania w zakresie niezbędnym dla Licencjobiorcy i na
                  polach eksploatacji, które są niezbędne do prawidłowego
                  korzystania z Oprogramowania i Oprogramowania Dodatkowego z
                  zastrzeżeniem wykorzystywania ich na polach eksploatacji
                  wymienionych w Umowie.
                </li>
                <li>
                  Udzielona licencja nie obejmuje uprawnienia do odpłatnego lub
                  nieodpłatnego rozpowszechniania, upubliczniania lub
                  udostępniania Oprogramowania i Oprogramowania Dodatkowego lub
                  ich kopii osobom trzecim, w tym w drodze użyczenia, najmu,
                  dzierżawy, dalszej licencji, udostępniania w sieci do pobrania
                  lub w formie subskrypcji jako usługa dostępu do Oprogramowania
                  lub Oprogramowania Dodatkowego.
                </li>
                <li>
                  Udzielona licencja uprawnia Licencjobiorcę do korzystania z
                  niej na polach eksploatacji wymienionych w Umowie, tj.
                  wykorzystywanie Oprogramowania lub Oprogramowania Dodatkowego
                  na potrzeby prowadzonej działalności gospodarczej zgodnie z
                  jej PKD oraz nieograniczone korzy-stanie w celach realizacji
                  zamierzeń Programu zgodnie z jego przeznaczeniem.
                </li>
                <li>
                  Licencjobiorca ma zakaz dostarczania treści o charakterze
                  bezprawnym.
                </li>
              </ol>
            </li>
            <li>
              Zobowiązania wzajemne
              <ol>
                <li>
                  Licencjodawca przekaże ustną lub pisemną instrukcję aktywacji
                  Oprogramowania lub Oprogramowania Dodatkowego na sprzęcie
                  Licencjobiorcy. Strony w drodze porozumienia uzgodnią jeden,
                  wspólnie wybrany termin spotkania organizacyjnego w siedzibie
                  Licencjobiorcy, podczas którego przedstawiciel Licencjodawcy
                  udzieli przeszkolenia pracownikom Licencjodawcy z podstawowych
                  zasad użytkowania Oprogramowania lub Oprogramowania
                  Dodatkowego.
                </li>
                <li>
                  Licencjodawca może kontrolować sposób korzystania z
                  Oprogramowania lub Oprogramowania Dodatkowego przez
                  Licencjobiorcę pod kątem jego zgodności z postanowieniami
                  niniejszej Umowy oraz przeznaczeniem Oprogramowania lub
                  Oprogramowania Dodatkowego, a Licencjobiorca zezwoli na taką
                  kontrolę na żądanie Licencjodawcy.
                </li>
                <li>
                  Strony zgodnie oświadczają, że zachowanie każdego użytkownika
                  końcowego są traktowane w ramach Regulaminu, w tym w
                  szczególności niniejszego Działu, jak zachowania
                  Licencjobiorcy, ze wszystkimi skutkami stąd wynikającymi dla
                  Licencjobiorcy.
                </li>
                <li>
                  O ile Strony zgodnie nie postanowią inaczej, w zakresie
                  wykraczającym poza opisane wyżej świadczenia włącznie z
                  przetwarzaniem przez Licencjodawcę gromadzonych przez
                  Licencjobiorcę danych, Licencjodawca nie realizuje żadnych
                  innych świadczeń na rzecz Licencjobiorcy.
                </li>
              </ol>
            </li>
            <li>
              Gwarancja i Reklamacje
              <ol>
                <li>
                  Licencjodawca udziela Licencjobiorcy gwarancji na
                  Oprogramowanie lub Oprogramowania Dodatkowego w okresie 12
                  miesięcy od dnia wdrożenia Programu na sprzęcie informatycznym
                  Licencjobiorcy. Warunkiem obowiązywania gwarancji jest
                  powstrzymanie się przez Licencjobiorcę lub osobę trzecią od
                  dokonywania jakichkolwiek zmian w kodzie źródłowym Programu.
                </li>
                <li>
                  Jeżeli w czasie trwania Umowy ujawnią się błędy w działaniu
                  Oprogramowania lub Oprogramowania Dodatkowego to Licencjodawca
                  według swojego wyboru i uznania, jednak nie później niż w
                  ciągu 7 dni od dnia zgłoszenia, usunie te wady lub wdroży
                  pozbawioną wad wersję Programu.
                </li>
                <li>
                  Reklamacje dotyczące Oprogramowania lub Oprogramowania
                  Dodatkowego mogą być składane przez Licencjobiorcę tylko w
                  okresie abonamentowym przypisanym temu Programowi którego
                  dotyczą.
                </li>
                <li>
                  Każda reklamacja winna zostać zgłoszona na adres e-mail
                  kontakt@celpal.net lub pocztą na adres ul. Jana Heweliusza
                  11/819, 80-890 Gdańsk oraz zawierać: dane Licencjobiorcy
                  pozwalające na ustalenie, że ma on status Licencjobiorcy,
                  szczegółowy opis problemu, numer telefonu; w przypadku adresu
                  do korespondencji - adres w Polsce; w przypadku zgłoszenia
                  reklamacji e-mailem Licencjodawca ma prawo dokonać zwrotnie
                  informacji o rozpatrzeniu informacji na ww. adres e-mail,
                  tudzież skorzystać z niego celem uzyskania dodatkowych danych
                  celem jej rozpatrzenia.
                </li>
              </ol>
            </li>
            <li>
              Odpowiedzialność na wypadek siły wyższej
              <ol>
                <li>
                  W takim zakresie, w jakim jest to dopuszczalne w świetle
                  obowiązujących przepisów prawnych, z zastrzeżeniem zwłaszcza
                  postanowień art. 473 § 2 k.c. wyłącza się odpowiedzialność
                  Licencjodawcy za wszelkie skutki związane z korzystaniem z
                  Oprogramowania lub Oprogramowania Dodatkowego, w tym za szkody
                  wynikłe z użytkowania lub niemożności jego użytkowania, w
                  szczególności zaś za szkody wynikłe z wad udostępnianego przez
                  Licencjodawcę Licencjobiorcy Oprogramowania lub Oprogramowania
                  Dodatkowego czy ew. błędów danych zawartych w Programie.
                  Powyższe wyłączenie odpowiedzialności obowiązuje także po
                  rozwiązaniu lub wygaśnięciu Umowy, niezależnie od tego, w
                  jakim trybie doszło do jej rozwiązania czy wygaśnięcia.
                </li>
                <li>
                  W takim zakresie, w jakim jest to dopuszczalne w świetle
                  obowiązujących przepisów prawnych, wyłącza się
                  odpowiedzialność Licencjodawcy z tytułu rękojmi za wady
                  Programu lub Oprogramowania Dodatkowego, niezależnie od ich
                  charakteru.
                </li>
                <li>
                  Żadna ze Stron nie odpowiada za niewykonanie Umowy spowodowane
                  siłą wyższą.
                </li>
                <li>
                  Przez siłę wyższą należy rozumieć zdarzenie zewnętrzne, o
                  charakterze nadzwyczajnym, któremu nie można zapobiec, nawet
                  przy zachowaniu najwyższego stopnia staranności, np. powodzie,
                  trzęsienia ziemi, wojna.
                </li>
                <li>
                  Każda ze Stron winna poinformować drugą stronę o
                  okolicznościach siły wyższej, niezwłocznie po otrzymaniu
                  wiadomości o nich, a jeżeli z powodu siły wyższej nie byłoby
                  to możliwe, niezwłocznie po ustaniu tych okoliczności oraz
                  dołożyć wszelkich starań w celu usunięcia skutków działań siły
                  wyższej dla wykonywania zobowiązań zaciągniętych w Umowie.
                </li>
              </ol>
            </li>
            <li>
              Postanowienia końcowe
              <ol>
                <li>
                  Licencjodawca zastrzega sobie prawo dokonywania zmian w
                  Regulaminie.
                </li>
                <li>
                  Zmiany Regulaminu zaczynają obowiązywać w momencie publikacji
                  ich na stronie.
                </li>
                <li>
                  W sprawach nieuregulowanych w Regulaminie zastosowanie
                  znajdują przepisy prawa polskiego.
                </li>
                <li>
                  Wszelkie spory powstałe w związku z korzystaniem przez
                  Licencjobiorcę z usług Licencjodawcy rozstrzygane będą w razie
                  takiej potrzeby przez właściwy rzeczowo dla siedziby
                  Licencjodawcy polski sąd powszechny.
                </li>
                <li>
                  Niniejszy Regulamin jest udostępniany bezpłatnie i powszechnie
                  przed zawarciem Umowy.
                </li>
              </ol>
            </li>
          </ol>
        </Content>
      </Box>
    </PageSkeleton>
  )
}
