import React, { useState } from 'react'
import { LogoMark } from '../Logo'
import styled, { css } from 'styled-components'
import { MenuItem } from './MenuItem'
import { Link } from 'react-router-dom'
import { MdMenu, MdClose, MdHome } from 'react-icons/md'

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(isOpen => !isOpen)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  return (
    <HeaderStyled>
      <LogoLink to="/">
        <LogoMarkStyled light />
      </LogoLink>
      <MenuToggle onClick={toggleMenu} isOpen={menuOpen}>
        {!menuOpen ? <MdMenu size={28} /> : <MdClose size={28} />}
      </MenuToggle>
      <Menu isOpen={menuOpen}>
        <MenuItem to="/" style={{ order: 0 }}>
          <MdHome size={24} />
        </MenuItem>
        <MenuItem title="Produkt">
          {/* TODO: add a separate "Jak to działa?" page with YouTube videos */}
          <MenuItem href="/#jaktodziala" onClick={closeMenu}>
            Jak to działa?
          </MenuItem>
          <MenuItem to="/produkt">Funkcje</MenuItem>
          <MenuItem to="/bezpieczenstwo">Bezpieczeństwo</MenuItem>
          <MenuItem to="/prawo">Prawo</MenuItem>
          <MenuItem to="/ui">UI / UX</MenuItem>
        </MenuItem>
        <MenuItem to="/cennik">Oferta</MenuItem>
        {/* TODO: add recommendations when more clients come */}
        {/*<MenuItem href="#rekomendacje" title="Rekomendacje" />*/}
        <MenuItem to="/pytania">Pytania</MenuItem>
        <MenuItem to="/kontakt">Kontakt</MenuItem>
        {/* TODO: implement this */}
        {/*<MenuItemRegister>Rejestracja</MenuItemRegister>*/}
        <MenuItemLogin href="https://admin.celpal.net">
          Zaloguj się
        </MenuItemLogin>
      </Menu>
    </HeaderStyled>
  )
}

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #282c34;
  min-height: 60px;
  padding: 12px 0;
`

const LogoMarkStyled = styled(LogoMark)`
  width: 40px;
`

const MenuToggle = styled.div`
  width: 28px;
  color: #fff;
  margin: 0 16px;

  ${p =>
    p.isOpen &&
    css`
      position: fixed;
      right: 0;
      z-index: 2;
    `};

  @media (min-width: 768px) {
    display: none;
  }
`

const Menu = styled.nav`
  display: ${p => (p.isOpen ? 'flex' : 'none')};

  ${p =>
    p.isOpen &&
    css`
      flex-direction: column;
      align-items: flex-end;
      background: #282c34;
      position: fixed;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 84px;
      overflow: auto;
    `};

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    position: static;
    padding: 0;
    margin: 0 16px;
  }
`

const LogoLink = styled(Link)`
  margin: 10px 32px;
`

// TODO: bring it back when registration is available
// const MenuItemRegister = styled(MenuItem)`
//   color: #9cd2ff;
//   text-decoration: underline;
//
//   &:hover {
//     color: #82b6e2;
//   }
// `

const MenuItemLogin = styled(MenuItem)`
  background: #1385e4;
  padding: 12px 16px;
  border-radius: 4px;
  transition: background-color 100ms;
  cursor: pointer;
  order: 0;

  &:hover {
    background: #136fce;
    color: #fff;
    text-decoration: none;
  }
  
  @media (min-width: 768px) {
    order: 1;
  }
`
