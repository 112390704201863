import React from 'react'
import { SectionHeading, SectionSubHeading, SectionText } from '../Section'
import { LogoInline } from '../LogoInline'
import { PageSkeleton } from '../Section/PageSkeleton'

export const Law = () => {
  return (
    <PageSkeleton>
      <SectionHeading>System zgodny z prawem</SectionHeading>
      <SectionText>
        Wielu z Państwa pyta o bezpieczeństwo naszego systemu pod kątem prawnym.
        Uzyskaliśmy opinię prawną{' '}
        <a
          href="http://adwokatkotarbinska.pl"
          rel="noopener noreferrer"
          target="_blank"
        >
          Kancelarii Prawnej Adwokat Anny Bober-Kotarbińskiej
        </a>
        , która pozwala zakwalifikować Elektroniczną Książkę <LogoInline /> jako
        produkt zgodny z prawem.
      </SectionText>
      <SectionSubHeading>
        Rozporządzenie w sprawie wzorcowego regulaminu strzelnic
      </SectionSubHeading>
      <SectionText>
        Rozporządzenie Ministra Spraw Wewnętrznych i Administracji z dnia 15
        marca 2000 r. w sprawie wzorcowego regulaminu strzelnic określna dane,
        jakie należy przechowywać w książce pobytu na strzelnicy. Nasz system
        spełnia te wymogi, jednocześnie pozostając łatwym i intuicyjnym w
        użyciu.
      </SectionText>
      <SectionSubHeading>Podpis elektroniczny</SectionSubHeading>
      <SectionText>
        Kwestia podpisu elektronicznego jest jednym z najbardziej spornych
        tematów w dyskusji o rozwiązaniach informatycznych dla strzelnic.
        Uzyskana przez nas opinia prawna potwierdza, że podpis złożony odręcznie
        na ekranie tabletu czy smartfonu jest pełnoprawnym oświadczeniem woli.
      </SectionText>
    </PageSkeleton>
  )
}
