import React from 'react'
import styled from 'styled-components'
import { accentColor, mainColor } from './Logo'
import { bool } from 'prop-types'

const LogoMarkSvg = ({ light, ...props }) => {
  return (
    <svg
      xmlSpace="preserve"
      version="1.1"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="1.9 3.284 16.2 13.432"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        fill={light ? '#fff' : mainColor}
        d="
          M 6.9567 3.2838
          l 1.8677 0
          c -0.0023,0.0981 -0.0034,0.1987 -0.0034,0.3021
          l 0 3.056 -1.8643 0
          c -0.4627,0 -0.8617,0.1651 -1.1919,0.4953 -0.3302,0.3301 -0.4953,0.7248 -0.4953,1.1797
          l 0 0.0041 -3.3581 0
          c 0.001,-1.3834 0.4933,-2.5689 1.4778,-3.5555 0.9834,-0.9853 2.1743,-1.4817 3.5675,-1.4817
          z
          m 4.2188 0
          l 1.88 0
          c 1.3851,0 2.5721,0.4963 3.5554,1.4817 0.9845,0.9865 1.4768,2.1722 1.4777,3.5555
          l -3.3581 0 0 -0.0041
          c 0,-0.4549 -0.1691,-0.8496 -0.5033,-1.1797 -0.3342,-0.3302 -0.725,-0.4953 -1.1717,-0.4953
          l -1.8764 0 0 -3.056
          c 0,-0.1034 -0.0012,-0.204 -0.0036,-0.3021
          z
          m 6.9131 8.3953
          c -0.0009,1.3912 -0.4921,2.5779 -1.4777,3.5634 -0.9825,0.9825 -2.1705,1.4737 -3.5554,1.4737
          l -1.8764 0 0 -3.3581 1.8764 0
          c 0.4469,0 0.8375,-0.165 1.1717,-0.4952 0.3323,-0.3282 0.5013,-0.7242 0.5033,-1.1838
          l 3.3581 0
          z
          m -9.2676 5.0371
          l -1.8643 0
          c -1.393,0 -2.585,-0.4913 -3.5675,-1.4737 -0.9855,-0.9855 -1.4768,-2.1721 -1.4778,-3.5634
          l 3.3581 0
          c 0.002,0.4596 0.1671,0.8556 0.4953,1.1838 0.3302,0.3302 0.7288,0.4952 1.1919,0.4952
          l 1.8643 0 0 3.3581
          z "
      />
      <path
        fill={light ? '#fff' : accentColor}
        d="
          M 9.5853 8.321
          l 0.8415 0
          c 0.8335,0 1.2523,0.4227 1.2523,1.2643
          l 0 0.8415
          c 0,0.8335 -0.4188,1.2523 -1.2523,1.2523
          l -0.8415 0
          c -0.8415,0 -1.2643,-0.4188 -1.2643,-1.2523
          l 0 -0.8415
          c 0,-0.8416 0.4228,-1.2643 1.2643,-1.2643
          z "
      />
    </svg>
  )
}

export const LogoMark = styled(LogoMarkSvg)`
  width: 80px;
`

LogoMark.propTypes = {
  light: bool
}
