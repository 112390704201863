import React from 'react'
import { PageSkeleton } from '../Section/PageSkeleton'
import { SectionHeading } from '../Section'
import { Box } from '@material-ui/core'
import { LogoInline } from '../LogoInline'
import DateTime from 'luxon/src/datetime'
import { Content } from './TermsContent'

export const TermsOfUseMobileApp = () => {
  const lastUpdatedAt = DateTime.local(2021, 4, 1).setLocale('pl')
  const gdprVendors = [
    'ScaleGrid, Inc.',
    'SFDC Ireland Limited',
    'Google Cloud Poland Sp. z o.o'
  ]

  return (
    <PageSkeleton>
      <SectionHeading>
        Regulamin Aplikacji Mobilnej <LogoInline />
      </SectionHeading>
      <Box display="flex" justifyContent="center">
        <Content>
          <ol>
            <li>POSTANOWIENIA OGÓLNE</li>

            <ol>
              <li>
                Definicje:
                <ul>
                  <li>
                    <strong>Regulamin</strong> - niniejszy regulamin;
                  </li>

                  <li>
                    <strong>Operator/Operator Aplikacji</strong> – KODEUSZ
                    SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ KRS 0000830481 NIP
                    5833390660 REGON 385618552. Data rejestracji 21 lutego 2020
                    r. Adres siedziby Jana Heweliusza 11 / 819, 80-890 Gdańsk,
                    Polska, adres e-mail: kontakt@celpal.net;
                  </li>

                  <li>
                    <strong>Użytkownik</strong> – osoba fizyczna, która korzysta
                    z Usługi świadczonej drogą elektroniczną w ramach Aplikacji;
                  </li>

                  <li>
                    <strong>Aplikacja</strong> – oprogramowanie pod nazwą
                    CEL-PAL, przeznaczone do instalacji w Urządzeniach Mobilnych
                    Użytkownika i umożliwiające korzystanie z Usług Serwisu w
                    zakresie w jakim są one udostępniane za pośrednictwem
                    Aplikacji.
                  </li>

                  <li>
                    <strong>
                      Dane wymagane do wpisu do Książki Strzelnicy
                    </strong>{' '}
                    – imię, nazwisko, adres zamieszkania (ulica, numer domu,
                    numer mieszkania, kod pocztowy, miejscowość), numer wydanego
                    pozwolenia na broń oraz nazwa organu wydającego to
                    pozwolenie;
                  </li>

                  <li>
                    <strong>Serwis</strong> – serwis internetowy umieszony w
                    szczególności w domenie www.celpal.net, dostępny również za
                    pośrednictwem Aplikacji, przy czym zakres Usług dostępnych
                    za pośrednictwem Aplikacji jest szerszy w stosunku do
                    zakresu Usług dostępnego za pośrednictwem stron www Serwisu;
                  </li>

                  <li>
                    <strong>Urządzenie Mobilne</strong> – przenośne urządzenie
                    elektroniczne przeznaczone do podłączenia bezpośrednio lub
                    pośrednio do zakończeń sieci, tj. telekomunikacyjne
                    urządzenie końcowe w rozumieniu art. 2 pkt 43) ustawy z dnia
                    16 lipca 2014 r. – Prawo telekomunikacyjne (smartfon,
                    tablet), korzystające z systemu operacyjnego wskazanego w
                    dalszej części Regulaminu.
                  </li>

                  <li>
                    <strong>Usługi</strong> – wszelkie usługi świadczone drogą
                    elektroniczną przez Operatora na rzecz Użytkowników z
                    wykorzystaniem Aplikacji w oparciu o niniejszy Regulamin
                    oraz polegająca na udostępnieniu Użytkownikowi
                    oprogramowania Aplikacji, umożliwiającej mu korzystanie z
                    funkcji Aplikacji.
                  </li>

                  <li>
                    <strong>Świadczenie usługi drogą elektroniczną</strong> –
                    wykonanie usługi świadczonej bez jednoczesnej obecności
                    stron (na odległość), poprzez przekaz danych na indywidualne
                    żądanie Usługobiorcy, przesyłanej i otrzymywanej za pomocą
                    urządzeń do elektronicznego przetwarzania, włącznie z
                    kompresją cyfrową, i przechowywania danych, która jest w
                    całości nadawana, odbierana lub transmitowana za pomocą
                    sieci telekomunikacyjnej w rozumieniu ustawy z dnia 16 lipca
                    2004 r. - Prawo telekomunikacyjne.
                  </li>
                </ul>
              </li>

              <li>
                Regulamin określa ogólne zasady korzystania z Aplikacji oraz
                Serwisu dostępnego za pośrednictwem Aplikacji, w tym ogólne
                zasady realizacji usług świadczonych drogą elektroniczną w
                rozumieniu Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług
                drogą elektroniczną.
              </li>
              <li>Serwis jest administrowany przez Operatora.</li>
              <li>
                Regulamin udostępniany jest nieodpłatnie przed zawarciem umowy o
                świadczenie usług drogą elektroniczną, a także na żądanie
                Użytkownika w taki sposób, który umożliwia pozyskanie,
                odtwarzanie i utrwalanie treści Regulaminu za pomocą systemu
                teleinformatycznego, którym posługuje się Użytkownik.
              </li>
              <li>
                Pobranie oraz korzystanie z Aplikacji jest bezpłatne, za
                wyjątkiem kosztów związanych z dostępem do Internetu i kosztów
                transmisji danych, które Użytkownik zobowiązany jest pokryć we
                własnym zakresie zgodnie z taryfą operatora telekomunikacyjnego,
              </li>
              <li>
                Korzystanie z Aplikacji wymaga Urządzenia Mobilnego wyposażonego
                w zgodny system operacyjny Android lub iOS, tj.:
                <ul>
                  <li>iOS wersja 7.x i wyższe – smartfony;</li>
                  <li>Android wersja 10.x i wyższe – smartfony;</li>
                </ul>
              </li>
              <li>
                Korzystanie z funkcjonalności Aplikacji wymaga dostępu do
                Internetu.
              </li>
              <li>
                W celu skorzystania z Usług świadczonych w ramach Aplikacji
                konieczne jest posiadanie adresu email.
              </li>
              <li>
                Korzystanie z Aplikacji może wiązać się z koniecznością
                uzyskiwania jej aktualizacji udostępnianych przez Operatora. W
                przypadku korzystania przez Użytkownika z Serwisu dostępnego za
                pośrednictwem stron www Serwisu, do Usług świadczonych w ramach
                tego Serwisu będzie miał zastosowanie Regulamin serwisu
                internetowego CEL-PAL.
              </li>
            </ol>

            <li>
              UŻYTKOWNIK
              <ol>
                <li>
                  Status Użytkownika Aplikacji uzyskuje każda korzystająca z
                  niego pełnoletnia osoba fizyczna posiadająca w niezbędnym
                  zakresie zdolność do czynności prawnych. Korzystanie z
                  Aplikacji jest dobrowolne. W przypadku braku akceptacji
                  któregokolwiek z postanowień Regulaminu dana osoba jest
                  zobowiązana do niekorzystania z Aplikacji.
                </li>
                <li>
                  Użytkownicy Aplikacji zobowiązani są do przestrzegania
                  Regulaminu Aplikacji.
                </li>
                <li>
                  Korzystanie z Aplikacji możliwe jest na dwóch poziomach:
                </li>
                <li>
                  Użytkownika zarejestrowanego nie podającego danych wymaganych
                  do wpisu do Książki Strzelnicy;
                </li>
                <li>
                  Użytkownika zarejestrowanego podającego dane wymagane do wpisu
                  do Książki Strzelnicy; co umożliwia skorzystanie z pełnej
                  funkcjonalności Aplikacji.
                </li>
                <li>
                  Rejestracja w Serwisie za pomocą Aplikacji umożliwia
                  korzystanie z Usług oferowanych w ramach Aplikacji, dostępnych
                  tylko dla Użytkowników posiadających status Użytkowników
                  zarejestrowanych którzy podali dane wymagane do wpisu do
                  Książki Strzelnicy.
                </li>
              </ol>
            </li>

            <li>
              REJESTRACJA/LOGOWANIE
              <ol>
                <li>
                  Logowanie poprzez konto posiadane przez Użytkownika w Serwisie
                  <br />
                  Użytkownik posiadający konto w Serwisie ma możliwość
                  zalogowania się do Serwisu za pomocą Aplikacji poprzez
                  wpisanie loginu i hasła do konta, które posiada w Serwisie
                  dostępnym za pomocą stron www Serwisu.
                </li>
                <li>
                  Rejestracja za pomocą Aplikacji
                  <ol>
                    <li>
                      W przypadku gdy Użytkownik nie posiada jeszcze konta w
                      Serwisie, Użytkownik może je utworzyć (zarejestrować się)
                      za pośrednictwem Aplikacji. W celu dokonania rejestracji
                      bezpośrednio z poziomu Aplikacji wymagane jest wypełnienie
                      przez zainteresowanego Użytkownika odpowiedniego
                      formularza wygenerowanego przez Aplikację po kliknięciu
                      opcji „rejestracja”.
                    </li>
                    <li>
                      Formularz wymaga udostępnienia przez zainteresowanego
                      Użytkownika danych, o które zostanie poproszony w ramach
                      formularza. Będą to:
                      <ul>
                        <li>Adres e-mail Użytkownika;</li>
                        <li>
                          Proponowane hasło, przy czym ze względów
                          bezpieczeństwa powinno się ono składać z co najmniej 8
                          znaków (małe i duże litery oraz cyfry lub znaki
                          specjalne). Operator zapewnia wysoki poziom
                          zabezpieczeń dostępu do kont poszczególnych
                          Użytkowników, niemniej jednak to Użytkownik pozostaje
                          dysponentem swojego hasła i w tym zakresie jest
                          wyłącznie odpowiedzialny za wszelkie działania
                          związane z posługiwaniem się tym hasłem. Użytkownik
                          jest zobowiązany do utrzymania hasła w ścisłej
                          tajemnicy. Operator sugeruje Użytkownikom (1) taki
                          dobór hasła, aby jego odgadnięcie nie było możliwe
                          przez inne osoby lub programy komputerowe, oraz (2)
                          okresową, nie rzadszą niż raz na 30 dni zmianę hasła;
                        </li>
                      </ul>
                    </li>
                    <li>
                      Ponadto osoba rejestrująca ma możliwość zaznaczenia
                      następujących opcji dotyczących akceptacji Regulaminu wraz
                      z Polityką Prywatności;
                    </li>
                    <li>
                      Aby Użytkownik mógł się zarejestrować wymagane jest
                      dokonanie przez niego akceptacji Regulaminu wraz z
                      Polityką Prywatności, bez których świadczenie usług
                      przewidzianych dla zarejestrowanych Użytkowników nie
                      będzie możliwe. Po wypełnieniu formularza i kliknięciu
                      opcji „załóż konto” osoba rejestrująca się otrzyma na
                      podany przez siebie w formularzu adres e-mail wiadomość z
                      prośbą o kliknięcie w link, co spowoduje aktywację konta
                      Użytkownika (dalej: „Konto”). Użytkownik może połączyć
                      Aplikację z platformą mediów społecznościowych
                      Facebook.com, jednakże w przypadku połączenia konta
                      Użytkownika w Aplikacji z platformą mediów
                      społecznościowych, Użytkownik będzie podlegał warunkom
                      korzystania i polityce prywatności, określonym w polityce
                      tej platformy. Operator nie ponosi odpowiedzialności za
                      ewentualne spory mogące wyniknąć z operatorem platformy
                      mediów społecznościowych. Połączenie Aplikacji z platformą
                      mediów społecznościowych jest dobrowolne. Od tego momentu
                      Użytkownik będzie posiadał status Użytkownika
                      zarejestrowanego w Serwisie.
                    </li>
                    <li>
                      Użytkownik ma możliwość zmiany swoich danych wprowadzonych
                      przy rejestracji.
                    </li>
                    <li>
                      Użytkownik, dokonując rejestracji, jest wyłącznie
                      odpowiedzialny za prawidłowość, treść oraz formę wszelkich
                      przekazanych przez siebie danych oraz informacji.
                      Podawanie nieprawdziwych danych oraz informacji, w
                      szczególności danych osób trzecich lub danych fikcyjnych,
                      jest zakazane. Domniemywa się, że dane podane przez
                      Użytkownika dotyczą osoby w nich wskazanej.
                    </li>
                    <li>
                      Założenie Konta w Serwisie za pośrednictwem Aplikacji jest
                      równoznaczne z posiadaniem konta w ramach Serwisu
                      dostępnego za pośrednictwem stron www Serwisu. Użytkownik
                      zarejestrowany w Serwisie za pośrednictwem Aplikacji może
                      zalogować się do swojego konta w Serwisie dostępnym w
                      ramach stron www Serwisu korzystając z hasła i loginu
                      wykorzystywanego do logowania w Serwisie dostępnym za
                      pośrednictwem Aplikacji.
                    </li>
                    <li>
                      Aby Użytkownik mógł korzystać z pełnej funkcjonalności
                      aplikacji,
                      {/* TODO: needs review */}
                      {/*w szczególności w zakresie opisanym w pkt ____*/}
                      konieczne jest podanie danych umożliwiających dokonanie
                      wpisu do Książki Strzelnicy oraz uruchomienia usługi
                      geolokalizacji. Podanie tych danych jest dobrowolne, acz
                      niezbędne do korzystania z pełnej funkcjonalności
                      Aplikacji.
                    </li>
                  </ol>
                </li>

                <li>
                  Logowanie/Rejestracja poprzez serwisy społecznościowe
                  <ol>
                    <li>
                      Użytkownicy Aplikacji będący jednocześnie użytkownikami
                      serwisu społecznościowego Facebook mają możliwość
                      zalogowania i zarejestrowania się z wykorzystaniem
                      aplikacji Facebook Connect. Rejestrując się poprzez portal
                      Facebook Użytkownicy będą musieli zezwolić aplikacji
                      Facebook Connect na dostęp do ich danych osobowych
                      zawartych w ich profilu istniejącym w ramach portalu
                      Facebook, w zakresie niezbędnym do założenia Konta w
                      Serwisie, to jest danych w postaci adresu mailowego
                      Użytkownika. Z chwilą zarejestrowania Konta w Serwisie w
                      powyższy sposób administratorem takich danych osobowych
                      Użytkownika staje się Operator.
                    </li>
                    <li>
                      Zasady przetwarzania przez Operatora danych osobowych
                      Użytkowników, jak również zasady ochrony prywatności
                      Użytkowników, określone są w Polityce Prywatności,
                      stanowiącej integralną część Regulaminu.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li>
              ZAWARCIE I ROZWIĄZANIE UMOWY. ODSTĄPIENIE OD UMOWY
              <ol>
                <li>
                  Umowa o świadczenie usług drogą elektroniczną zostaje przez
                  Użytkownika zawarta w momencie rozpoczęcia korzystania z Usług
                  przez Użytkownika tj. odpowiednio: (1) pobrania i
                  zainstalowania Aplikacji na Urządzeniu Mobilnym (2) w
                  przypadku Usługi udostępnienia zawartości Serwisu za
                  pośrednictwem Aplikacji - z momentem uruchomienia Aplikacji
                  (3) po zarejestrowaniu lub logowaniu - w przypadku Usług, do
                  świadczenia których wymagana jest rejestracja
                </li>
                <li>
                  Umowa o świadczenie usług drogą elektroniczną ulega
                  rozwiązaniu z chwilą odpowiednio: (1) usunięcie Aplikacji z
                  Urządzenia Mobilnego; (2) opuszczenia przez Użytkownika
                  Aplikacji (3) wyrejestrowania się z Serwisu przez Użytkownika
                  (dezaktywacji Konta); (4) rezygnacji przez Użytkownika z danej
                  Usługi (poprzez przesłanie maila z żądaniem w przedmiotowym
                  zakresie na adres mailowy:{' '}
                  <a href="mailto:kontakt@celpal.net">kontakt@celpal.net</a>) 4)
                  decyzji Operatora o rozwiązaniu umowy, która może być
                  spowodowana (a) naruszeniami postanowień Regulaminu ze strony
                  Użytkownika (w szczególności polegającymi na niezgodności z
                  prawdą któregokolwiek z oświadczeń danego Użytkownika,
                  naruszeniu praw innych Użytkowników, Operatora) – działania
                  Operatora w tym zakresie podejmowane są stosownie do
                  szczegółowych postanowień Regulaminu) (b) odmową Użytkownika
                  udzielenia Operatora niezbędnych zgód lub danych niezbędnych
                  do świadczenia danej Usługi, (c) problemami technicznymi po
                  stronie Użytkownika, uniemożliwiającymi świadczenie dla niego
                  Usługi przez Operatora.
                </li>
                <li>
                  Prawo do rozwiązania umowy o świadczenie usług drogą
                  elektroniczną (stosownie do pkt 2 powyżej) przysługuje
                  Użytkownikowi w każdej chwili (bez zachowania okresu
                  wypowiedzenia).
                </li>
                <li>
                  Użytkownik może odstąpić od umowy o świadczenie usług drogą
                  elektroniczną w terminie 14 dni od jej zawarcia.
                </li>
                <li>
                  Odstąpienia od umowy, o którym mowa w ust. 4 Użytkownik
                  dokonuje przez przesłanie oświadczenia o odstąpieniu od Umowy
                  na piśmie (na adres Operatora: Kodeusz sp. z o. o., Jana
                  Heweliusza 11 / 819, 80-890 Gdańsk).
                </li>
                <li>
                  Uprawnienie do odstąpienia od umowy o świadczenie usług drogą
                  elektroniczną nie przysługuje Użytkownikowi w przypadku, gdy
                  za jego zgodą Operator rozpoczął świadczenie Usługi przed
                  upływem 14 dni od zawarcia umowy.
                </li>
                <li>
                  Użytkownik jest informowany o utracie prawa odstąpienia od
                  umowy zgodnie z obowiązującymi przepisami prawa.
                </li>
                <li>
                  Ściągnięcie Aplikacji prowadzi do natychmiastowego
                  dostarczenie Użytkownikowi treści cyfrowych.
                </li>
              </ol>
            </li>

            <li>
              WYREJESTOWANIE SIĘ UŻYTKOWNIKA Z SERWISU / DEZAKTYWACJA KONTA
              <ol>
                <li>
                  Użytkownikowi przysługuje w każdej chwili (bez zachowania
                  okresu wypowiedzenia) prawo do rozwiązania umowy o świadczenie
                  usług drogą elektroniczną poprzez żądanie wyrejestrowania się
                  z Serwisu.
                </li>
                <li>
                  Usunięcie Aplikacji z Urządzenia Mobilnego nie powoduje
                  dezaktywacji Konta, gdyż Użytkownik po usunięciu Aplikacji
                  może nadal zalogować się do swojego Konta za pośrednictwem
                  stron www Serwisu (używając tych samych danych (hasła i
                  loginu) jakich używał do logowania w Serwisie dostępnym za
                  pośrednictwem Aplikacji). Usunięcie Aplikacji z Urządzenia
                  Mobilnego uniemożliwia jednak korzystanie na tym Urządzeniu
                  Mobilnym z Serwisu za pośrednictwem Aplikacji.
                </li>
                <li>
                  Wyrejestrowanie następuje poprzez przesłanie maila z żądaniem
                  dezaktywacji Konta na adres mailowy:{' '}
                  <a href="mailto:kontakt@celpal.net">kontakt@celpal.net</a>.
                </li>
                <li>
                  W przypadku usunięcia na wniosek Użytkownika jakichkolwiek z
                  danych osobowych podawanych w formularzu, niezbędnych do
                  rejestracji lub cofnięcia przez Użytkownika zgody na
                  przetwarzanie danych osobowych w związku z usługami
                  świadczonymi w ramach Serwisu dostępnego za pośrednictwem
                  Aplikacji lub cofnięcia akceptacji dla Regulaminu, dalsze
                  świadczenie Usług jest niemożliwe i tym samym skutkuje
                  dezaktywacją Konta danego Użytkownika.
                </li>
                <li>
                  Dezaktywację Konta powoduje również naruszenie przez danego
                  Użytkownika któregokolwiek z postanowień Regulaminu, w
                  szczególności niezgodność z prawdą oświadczeń danego
                  Użytkownika, naruszenie praw innych Użytkowników, Operatora,
                  innych podmiotów trzecich lub zachowanie w ramach Aplikacji
                  sprzeczne z postanowieniami Regulaminu (z uwzględnieniem
                  postanowień punktu 7 Rozdziału VII „PRAWA WYŁĄCZNE”).
                </li>
                <li>
                  Fakt dezaktywacji Konta jest każdorazowo komunikowany danemu
                  Użytkownikowi za pośrednictwem wiadomości wysłanej przez
                  Operatora na adres e-mail podany podczas rejestracji.
                </li>
                <li>
                  Dezaktywacja Konta dokonana za pomocą Aplikacji powoduje
                  również dezaktywację konta Użytkownika w Serwisie dostępnym za
                  pośrednictwem stron www Serwisu. Dezaktywacja Konta dokonana w
                  Serwisie dostępnym za pośrednictwem stron www Serwisu powoduje
                  również dezaktywację Konta w ramach Aplikacji.
                </li>
              </ol>
            </li>

            <li>
              SPOSÓB KORZYSTANIA PRZEZ UŻYTKOWNIKÓW Z APLIKACJI
              <ol>
                <li>
                  Użytkownik zobowiązany jest do korzystania z Aplikacji oraz
                  Serwisu dostępnego za pośrednictwem Aplikacji w sposób zgodny
                  z Regulaminem, przepisami prawa, zasadami współżycia
                  społecznego, zwyczajami przyjętymi w Internecie (Netykieta)
                  oraz dobrymi obyczajami.
                </li>
                <li>
                  Użytkownik jest w szczególności zobowiązany do:
                  <ul>
                    <li>
                      powstrzymywania się od działań mogących utrudnić lub
                      zakłócić funkcjonowanie Serwisu;
                    </li>
                    <li>
                      nieprzekazywania nieprawdziwych danych osobowych, w
                      szczególności niedopuszczalnym jest tworzenie
                      nieistniejących użytkowników oraz przekazywanie danych
                      osobowych innych osób jako własnych danych osobowych,
                      korzystania z kont innych użytkowników czy udostępniania
                      własnych kont innym osobom;
                    </li>
                    <li>
                      aktualizacji danych niezbędnych do kontaktu z danym
                      Użytkownikiem;
                    </li>
                    <li>
                      powstrzymywania się od działań naruszających dobre imię
                      Operatora oraz podmiotów z nim współpracujących;
                    </li>
                    <li>
                      powstrzymywania się od innych zachowań, które mogłyby
                      zostać obiektywnie uznane za zachowania oczywiście
                      niepożądane, naganne lub sprzeczne z przeznaczeniem
                      Aplikacji lub Serwisu udostępnianego za jej pośrednictwem.
                    </li>
                  </ul>
                </li>

                <li>
                  Aplikacja udostępniana jest Użytkownikowi na użytek osobisty.
                  Użytkownik nie może wykorzystywać Aplikacji w celach
                  komercyjnych.
                </li>
                <li>
                  W przypadku jakichkolwiek pytań dotyczących funkcjonowania
                  Aplikacji lub sposobu korzystania z niej, Użytkownik może
                  skontaktować się poprzez przesłanie wiadomości mailowej na
                  adres:{' '}
                  <a href="mailto:kontakt@celpal.net">kontakt@celpal.net</a>.
                </li>
              </ol>
            </li>

            <li>
              PRAWA WYŁĄCZNE
              <ol>
                <li>
                  Wszystkie materiały znajdujące się w zasobach Aplikacji oraz
                  Serwisu dostępnego za pośrednictwem Aplikacji, obejmujące w
                  szczególności teksty, zdjęcia, infografiki, materiały filmowe,
                  dźwiękowe, rozwiązania nawigacyjne, wybór i układ treści oraz
                  grafik, kompilacje, oprogramowania oraz bazy danych, stanowią
                  przedmiot praw na dobrach niematerialnych do utworów lub baz
                  danych Operatora lub osób trzecich i podlegają ochronie na
                  podstawie ustawy o prawie autorskim i prawach pokrewnych,
                  ustawy o ochronie baz danych oraz konwencji międzynarodowych,
                  których stroną jest Rzeczpospolita Polska. Oznaczenia
                  umieszczane w Aplikacji oraz Serwisie dostępnym za
                  pośrednictwem Aplikacji podlegają ochronie na podstawie ustawy
                  – Prawo własności przemysłowej.
                </li>
                <li>
                  Niedopuszczalne jest korzystanie przez Użytkowników z
                  materiałów, o których mowa w punkcie 1 powyżej, w zakresie
                  wykraczającym poza użytek dozwolony na gruncie obowiązujących
                  przepisów prawa. W szczególności zabronione jest:
                  <ul>
                    <li>
                      zwielokrotnianie tych materiałów i umieszczanie ich w
                      innych Serwisach internetowych lub udostępnianie osobom
                      trzecim w inny sposób w całości lub w części wykraczającej
                      poza dozwoloną prawem;
                    </li>
                    <li>
                      umieszczanie odesłań do Serwisu w taki sposób, aby
                      utrudnione lub niemożliwe było poznanie źródła pochodzenia
                      materiałów;
                    </li>
                    <li>zwielokrotnianie materiałów na nośnikach;</li>
                    <li>
                      rozpowszechnianie i wprowadzanie do obrotu tak
                      wytworzonych egzemplarzy;
                    </li>
                    <li>
                      rozpowszechnianie opracowań materiałów lub szaty
                      graficznej niezależnie od przyczyn lub celu takiego
                      rozpowszechniania. Postanowienia powyższe stosuje się
                      odpowiednio do szaty graficznej Serwisu.
                    </li>
                  </ul>
                </li>
                <li>
                  Aplikacja oraz Serwis, jak również wszelkie ich aktualizacje,
                  adaptacje i modyfikacje stanowią przedmiot autorskich praw
                  majątkowych i prawa te podlegają ochronie na zasadach
                  określonych w ustawie o prawie autorskim i prawach pokrewnych.
                </li>
                <li>
                  O ile postanowienia niniejszego Regulaminu lub przepisy
                  powszechnie obowiązującego prawa nie stanowią wyraźnie
                  inaczej, Użytkownik nie może odtwarzać, powielać, kopiować,
                  sprzedawać, wprowadzać do obrotu Aplikacji ani żadnej jej
                  części, a także stosować technik inżynierii wstecznej,
                  dekompilować, dezasemblować, podejmować prób uzyskania kodu
                  źródłowego, modyfikować ani tworzyć opracowań (utworów
                  pochodnych) Aplikacji.
                </li>
              </ol>
            </li>

            <li>
              POSTĘPOWANIE REKLAMACYJNE
              <ol>
                <li>
                  Użytkownik ma prawo do składania reklamacji związanych z
                  działaniem Serwisu lub Aplikacji oraz reklamacji w zakresie
                  świadczonych Usług w ramach Serwisu dostępnego za
                  pośrednictwem Aplikacji.
                </li>
                <li>
                  Podmiotem uprawnionym do rozpatrywania reklamacji jest
                  Operator.
                </li>
                <li>
                  Reklamacje powinny być przesyłane w szczególności na adres
                  <a href="mailto:reklamacje@celpal.net">
                    reklamacje@celpal.net
                  </a>{' '}
                  w formie elektronicznej (wiadomość mailowa w polu temat
                  wiadomości powinna zawierać słowo: „reklamacja”) i powinny
                  zawierać: (1) przedmiot reklamacji oraz uzasadnienie, (2)
                  oznaczenie Użytkownika (imię, nazwisko, adres, adres e-mail),
                  (3) nazwę i model Urządzenia Mobilnego oraz (4) nazwę i wersję
                  systemu operacyjnego zainstalowanego na Urządzeniu Mobilnym.
                  Operator przyjmuje również reklamacje składane z
                  wykorzystaniem tradycyjnej przesyłki pocztowej, wysłanej na
                  adres korespondencyjny: Kodeusz sp. z o. o., Jana Heweliusza
                  11 / 819, 80-890 Gdańsk, z zamieszczonym na kopercie dopiskiem
                  „Reklamacja”.
                </li>
                <li>
                  Operator zastrzega sobie prawo zwrócenia się do Użytkownika
                  celem uzupełnienia informacji zawartych w reklamacji, jeżeli
                  podane przez Użytkownika informacje będą niepełne i
                  rozpatrzenie reklamacji będzie niemożliwe.
                </li>
                <li>
                  Reklamacje będą rozpatrywane przez Operatora w ciągu 14 dni od
                  ich otrzymania. Decyzja dotycząca reklamacji będzie
                  przekazywana Użytkownikowi na adres e-mail wskazany w
                  reklamacji.
                </li>
              </ol>
            </li>

            <li>
              ODPOWIEDZIALNOŚĆ / PRAWA I OBOWIĄZKI
              <ol>
                <li>
                  Operator zobowiązuje się dołożyć wszelkich starań w celu
                  zapewnienia prawidłowego i nieprzerwanego działania Serwisu i
                  jego dostępności przez całą dobę za pośrednictwem Aplikacji, a
                  także aby informacje zamieszczane w ramach Serwisu dostępnego
                  za pośrednictwem Aplikacji były jak najwyższej jakości.
                </li>
                <li>
                  Operator zobowiązuje się dołożyć wszelkich starań, by wszelkie
                  ewentualne nieścisłości techniczne oraz błędy typograficzne w
                  informacjach zawartych w Serwisie dostępnym za pośrednictwem
                  Aplikacji były eliminowane na bieżąco.
                </li>
                <li>
                  Operator zastrzega prawo do:
                  <ul>
                    <li>
                      Zmiany cech użytkowych i możliwości Serwisu dostępnego za
                      pośrednictwem Aplikacji lub Aplikacji, w szczególności
                      zakresu oraz rodzaju usług i funkcjonalności z zachowaniem
                      odpowiedniej procedury informowania Użytkowników o
                      wprowadzanych zmianach, z zastrzeżeniem, że w przypadku
                      zmian polegających na usunięciu określonych
                      funkcjonalności Serwisu dostępnego za pośrednictwem
                      Aplikacji lub Aplikacji stosowana będzie procedura
                      przewidziana w punkcie 1 rozdziału XII „Postanowienia
                      końcowe”;
                    </li>
                    <li>
                      Okresowych przerw technicznych niezbędnych do rozwijania
                      Serwisu oraz minimalizowania ryzyka wystąpienia awarii.
                      Operator dołoży wszelkich starań, aby nie wpływały one na
                      jakość świadczonych w ramach Serwisu usług;
                    </li>
                    <li>
                      Czasowego wstrzymania działania Aplikacji ze względu na
                      niezbędne prace konserwacyjne, prace związane z
                      modyfikacją lub inne prace mające na celu doskonalenie
                      funkcjonowania Aplikacji oraz minimalizowania ryzyka
                      wystąpienia awarii. Operator dołoży wszelkich starań, aby
                      nie wpływały one na jakość świadczonych w ramach Serwisu
                      usług;
                    </li>
                    <li>
                      Zaprzestania, po uprzednim powiadomieniu Użytkownika oraz
                      wezwaniu go do zaprzestania naruszeń, udostępniania
                      Serwisu dostępnego za pośrednictwem Aplikacji i
                      świadczenia usług w ramach Serwisu w stosunku do
                      Użytkownika, który narusza postanowienia Regulaminu.
                    </li>
                  </ul>
                </li>

                <li>
                  Operator zastrzega sobie prawo do zrezygnowania z prowadzenia
                  Serwisu lub zaprzestania udostępniania go za pośrednictwem
                  Aplikacji jak również do zaprzestania udostępniania Aplikacji
                  po wcześniejszym powiadomieniu o tym fakcie Użytkowników.
                </li>
                <li>
                  Operator zobowiązuje się dołożyć wszelkich starań, aby
                  wszelkie aplikacje umieszczone w ramach Serwisu dostępnego za
                  pośrednictwem Aplikacji działały poprawnie.
                </li>
                <li>
                  Operator nie świadczy usług archiwizowania plików, danych lub
                  informacji.
                </li>
              </ol>
            </li>

            <li>
              POSTANOWIENIA SZCZEGÓŁOWE – POSZCZEGÓLNE USŁUGI I FUNKCJONALNOŚCI
              W RAMACH APLIKACJI
              <br />
              <br />W ramach Serwisu dostępnego za pośrednictwem Aplikacji
              świadczone są w szczególności następujące Usługi:
              <ol>
                <li>
                  Po pobraniu i zainstalowaniu Aplikacji użytkownik może:
                  <ul>
                    <li>
                      zarejestrować konto Użytkownika w systemie Aplikacji
                    </li>
                    <li>
                      logować się przy użyciu danych podanych przy rejestracji
                    </li>
                    <li>
                      wyszukiwania strzelnic, przy czym dla Użytkownika widoczne
                      będą tylko strzelnice korzystające z systemu aplikacji
                      CEL-PAL, znajdujące się w promieniu 300 m od
                      geolokalizacji Użytkownika.
                    </li>
                    <li>
                      przeglądać mapę strzelnic korzystających z systemu
                      rejestracji Aplikacji CEL-PAL i widniejących w bazie
                      danych
                    </li>
                    <li>
                      uwierzytelnić konto użytkownika poprzez podanie danych
                      wskazanych w pkt 2.2 Regulaminu, co umożliwia dokonanie
                      wpisu do Książki Strzelnicy na wybranej przez Użytkownika
                      strzelnicy
                    </li>
                  </ul>
                </li>
              </ol>
            </li>

            <li>
              OCHRONA DANYCH OSOBOWYCH
              <ol>
                <li>
                  Administratorem danych osobowych, podanych przez Użytkowników
                  w związku z korzystaniem z Aplikacji, jest Operator.
                </li>
                <li>
                  Dane osobowe podane przez Użytkownika podczas rejestracji,
                  późniejszej edycji konta w Aplikacji oraz w momencie
                  przystąpienia do korzystania z usług świadczonych drogą
                  elektroniczną w ramach Aplikacji będą przetwarzane przez
                  Administratora, przede wszystkim, w celu wykonania zawartej
                  umowy na świadczenie usług drogą elektroniczną na warunkach
                  opisanych w Regulaminie (nawiązania, ukształtowania treści,
                  zmiany lub rozwiązania stosunku prawnego w ramach Usług
                  świadczonych w ramach Aplikacji oraz rozpatrzenia ewentualnych
                  reklamacji). Podstawę prawną takiego przetwarzania stanowi
                  art. 6 ust. 1 lit b) rozporządzenia Parlamentu Europejskiego i
                  Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                  ochrony osób fizycznych w związku z przetwarzaniem danych
                  osobowych i w sprawie swobodnego przepływu takich danych oraz
                  uchylenia dyrektywy 95/46/WE (dalej jako „ogólne
                  rozporządzenie o ochronie danych”). Ponadto, dane te będą
                  przetwarzane przez Administratora w celu marketingu usług
                  własnych (w przypadkach, gdy przepisy prawa dopuszczają
                  przetwarzanie danych w tym celu bez zgody osoby, której dane
                  dotyczą), w oparciu o podstawę prawną określoną w art. 6 ust.
                  1 lit. f) ogólnego rozporządzenia o ochronie danych.
                </li>
                <li>
                  Administrator uprawniony jest do przetwarzania danych
                  eksploatacyjnych charakteryzujących sposób korzystania przez
                  Użytkowników Aplikacji z usług świadczonych drogą
                  elektroniczną w ramach Aplikacji, zgodnie z u.ś.d.e.
                </li>
                <li>
                  Administrator jest w posiadaniu adresów IP Urządzeń Mobilnych,
                  za pośrednictwem których Użytkownicy korzystają z Aplikacji.
                </li>
                <li>
                  Dane osobowe Użytkownika będą przechowywane przez czas trwania
                  umowy o świadczenie usług drogą elektroniczną zawartej w
                  ramach Aplikacji oraz po jej zakończeniu, w celu realizacji
                  przez Administratora praw i obowiązków wynikających z
                  obowiązujących przepisów prawa, w tym w szczególności
                  obowiązków podatkowych.
                </li>
                <li>
                  Użytkownikowi przysługuje prawo do dostępu do swoich danych
                  osobowych, ich sprostowania, usunięcia lub ograniczenia
                  przetwarzania, prawo do sprzeciwu wobec dalszego przetwarzania
                  (w przypadku, gdy podstawą przetwarzania jest usprawiedliwiony
                  interes administratora), prawo do przenoszenia danych
                  osobowych oraz prawo do wniesienia skargi do organu
                  nadzorczego w zakresie ochrony danych osobowych.
                </li>
                <li>
                  Podanie danych osobowych podczas rejestracji lub korzystania z
                  usług w Aplikacji jest dobrowolne, ale niezbędne w celu
                  zarejestrowania się w Aplikacji lub skorzystania z usług,
                  które wymagają podania danych osobowych w Aplikacji.
                </li>
                <li>
                  Wszelkie zgody wyrażone ewentualnie przez Użytkownika
                  dotyczące przetwarzania danych osobowych Użytkownika lub
                  przesyłania informacji handlowych mogą być w każdej chwili
                  odwołane. Wszelka korespondencja w ww. zakresie powinna być
                  kierowana do Administratora na adres:{' '}
                  <a href="mailto:rodo@celpal.net">rodo@celpal.net</a>
                </li>
                <li>
                  Administrator traktuje ochronę prywatności, w tym ochronę
                  danych osobowych Użytkowników priorytetowo i dokłada wszelkich
                  starań, aby prywatność ta była chroniona. Administrator
                  stosuje wszelkie środki ostrożności, aby przekazywane przez
                  Użytkowników Aplikacji dane osobowe były chronione przed
                  utratą, zniszczeniem, ujawnieniem, dostępem osób niepowołanych
                  lub niewłaściwym wykorzystaniem danych. Odpowiednimi
                  działaniami Użytkownicy mogą przyczynić się do zwiększenia
                  bezpieczeństwa swoich danych osobowych w Internecie (m.in.
                  poprzez częstą zmianę hasła dostępowego, stosowanie w nich
                  kombinacji liter i cyfr).
                </li>
                <li>
                  W razie zaistnienia takiej konieczności dane Użytkowników mogą
                  zostać udostępnione podmiotom upoważnionym na podstawie
                  przepisów prawa. Przetwarzanie danych osobowych Użytkowników
                  zostało powierzone w zakresie niezbędnym do przechowywania
                  (hostowania) Serwisu następującym podmiotom:
                  <ul>
                    {gdprVendors.map(vendor => (
                      <li key={vendor}>{vendor}</li>
                    ))}
                  </ul>
                </li>
                <li>
                  Niniejszy dokument dotyczy jedynie Aplikacji i Serwisu
                  dostępnego za pośrednictwem Aplikacji. Serwis dostępny za
                  pośrednictwem Aplikacji może zawierać odnośniki do stron WWW.
                  Operator nie ma wpływu na zasady prywatności obowiązujące w
                  ramach takich stron WWW (innych niż strona Serwisu), na które
                  będzie wchodził Użytkownik poprzez linki zamieszczone w ramach
                  Serwisu dostępnego za pośrednictwem Aplikacji. Użytkownik
                  wchodząc poprzez linki umieszczone w ramach Serwisu dostępnego
                  za pośrednictwem Aplikacji na takie strony WWW powinien
                  zapoznać się z polityką prywatności obowiązującą w ramach tych
                  stron.
                </li>
                <li>
                  Wszelkie zmiany dotyczące postanowień w ramach niniejszego
                  Regulaminu, spowodowane w szczególności wzbogaceniem katalogu
                  usług oferowanych w ramach Serwisu dostępnego za pośrednictwem
                  Aplikacji czy samej Aplikacji, modernizacji, aktualizacji
                  Aplikacji, zmianami w systemie prawa w zakresie ochrony
                  prywatności, będą komunikowane Użytkownikom poprzez
                  zmieszczenie informacji w Aplikacji oraz poprzez przesłanie na
                  adres mailowy podany podczas rejestracji, odpowiedniej
                  informacji. Wszelkie zmiany wchodzić będą w życie w terminie
                  nie krótszym niż 7 dni od momentu poinformowania Użytkownika o
                  planowanych zmianach w sposób wskazany w zdaniu poprzednim.
                </li>
              </ol>
            </li>

            <li>
              POSTANOWIENIA KOŃCOWE
              <ol>
                <li>
                  Operatorowi przysługuje prawo do zmiany postanowień
                  Regulaminu. Informacje dotyczące zmian Regulaminu będą
                  komunikowane Użytkownikom w terminie 7 dni przed ich wejściem
                  w życie poprzez umieszczenie informacji w Aplikacji oraz drogą
                  elektroniczną poprzez przesłanie komunikatu systemowego na
                  adres mailowy podany podczas rejestracji.
                </li>
                <li>
                  Użytkownikowi przysługuje prawo do natychmiastowego
                  rozwiązania umowy o świadczenie usług drogą elektroniczną
                  niezwłocznie po otrzymaniu informacji wskazanej w punkcie 1
                  powyżej, przy zastosowaniu procedury opisanej w Rozdziale V
                  „Wyrejestrowanie się użytkownika z Aplikacji/Dezaktywacja
                  konta”. Użytkownik niezarejestrowany, nie akceptujący zmian w
                  Regulaminie, powinien zaprzestać korzystania z Aplikacji i
                  Serwisu dostępnego za jej pośrednictwem.
                </li>
                <li>
                  W przypadku stwierdzenia, że którekolwiek z postanowień
                  Regulaminu jest z jakichkolwiek powodów, w części lub w
                  całości, dotknięte sankcją nieważności, Operator zobowiązuje
                  się do dokonania takich zmian jego treści, by nieważność ową
                  usunąć. Nieważność jednego z postanowień Regulaminu nie ma
                  wpływu na ważność pozostałych jego postanowień.
                </li>
                <li>
                  Ewentualne spory mogące powstać w związku ze stosowaniem
                  Regulaminu rozstrzygane będą przez właściwe sądy powszechne.
                </li>
                <li>
                  Regulamin niniejszy wchodzi w życie z dniem{' '}
                  {lastUpdatedAt.toLocaleString(DateTime.DATE_FULL)}.
                </li>
              </ol>
            </li>
          </ol>
        </Content>
      </Box>
    </PageSkeleton>
  )
}
