import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import { Logo } from '../Logo'
import { MdArrowDownward } from 'react-icons/md'

export const Intro = ({ onProceed }) => {
  const [background, setBackground] = useState(null)

  useEffect(() => {
    const blurImage = new Image()
    const image = new Image()
    blurImage.onload = () => {
      setBackground('/bg/celpal_bg_blur.jpg')
      image.onload = () => {
        setBackground('/bg/celpal_bg.jpg')
      }
      image.src = '/bg/celpal_bg.jpg'
    }
    blurImage.src = '/bg/celpal_bg_blur.jpg'

    return () => {
      blurImage.onload = null
      image.onload = null
    }
  }, [])

  return (
    <Wrapper background={background}>
      <LogoStyled light />
      <Title>
        {/*Chwytliwe hasło*/}
        Twoja nowoczesna strzelnica
        <br />
        {/*masło trzasło.*/}
        zawsze pod ręką.
        {/*na Twoim smartfonie.*/}
      </Title>
      <Link onClick={onProceed}>
        Więcej <DownArrow />
      </Link>
    </Wrapper>
  )
}

Intro.propTypes = {
  onProceed: func.isRequired
}

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-size: cover;
  background-color: #2f2f2f;
  position: relative;
  ${p => !!p.background && `background-image: url(${p.background});`};

  color: #fff;

  transition: background-image 2s ease-out;

  @media (min-width: 600px) {
    padding: 0;
  }
`

const Title = styled.div`
  font-size: 33px;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 36px;
  }
`

const LogoStyled = styled(Logo)`
  display: block;
  margin-bottom: 20px;
  width: 320px;

  @media (min-width: 768px) {
    width: 420px;
  }
`

const Link = styled.a`
  font-size: 24px;
  margin-top: 36px;
  transition: all 70ms ease-in;
  color: inherit;
  cursor: pointer;

  &:hover {
    transform: scale(1.1) skew(1deg);
    text-shadow: 0 0 4px #000;
  }
`

const DownArrow = styled(MdArrowDownward)`
  vertical-align: middle;
  font-size: 1.4em;
  margin-top: -3px;
`
