import styled from 'styled-components'
import { bool } from 'prop-types'

export const SectionText = styled.div`
  max-width: 760px;
  font-size: ${p => (p.large ? '26px' : '18px')};
  line-height: 1.5;
`

SectionText.propTypes = {
  large: bool
}

SectionText.defaultProps = {
  large: false
}
