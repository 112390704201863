import { useCallback, useEffect, useState } from 'react'

const getScreenWidth = () => window.innerWidth

export const useMobile = (breakpoint = 768) => {
  const [width, setWidth] = useState(getScreenWidth())

  const handler = useCallback(() => {
    setWidth(getScreenWidth())
  }, [setWidth])

  useEffect(() => {
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  })

  return width < breakpoint
}