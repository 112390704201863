import React, { useCallback, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import {
  Section,
  SectionContent,
  SectionHeading,
  SectionText
} from '../Section'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { MdArrowDownward, MdCheck, MdArrowUpward } from 'react-icons/md'
import { Collapse } from 'react-collapse'
import { LogoInline } from '../LogoInline'
import { Header } from '../Header'
import { Footer } from '../Footer'

const FeatureNames = {
  BOOK: 'BOOK',
  MOBILE_APP: 'MOBILE_APP',
  REGISTRY: 'REGISTRY',
  CARD: 'CARD',
  ROOM_ACCESS: 'ROOM_ACCESS'
}

export const Product = () => {
  const [expandedFeature, setExpandedFeature] = useState(null)
  const isExpandedFeature = useCallback(
    feature => expandedFeature === feature,
    [expandedFeature]
  )
  const toggleBookFeature = useCallback(() => {
    setExpandedFeature(
      isExpandedFeature(FeatureNames.BOOK) ? null : FeatureNames.BOOK
    )
  }, [isExpandedFeature, setExpandedFeature])
  const toggleRegistryFeature = useCallback(() => {
    setExpandedFeature(
      isExpandedFeature(FeatureNames.REGISTRY) ? null : FeatureNames.REGISTRY
    )
  }, [isExpandedFeature, setExpandedFeature])
  const toggleMemberCardFeature = useCallback(() => {
    setExpandedFeature(
      isExpandedFeature(FeatureNames.CARD) ? null : FeatureNames.CARD
    )
  }, [isExpandedFeature, setExpandedFeature])
  const toggleRoomAccessFeature = useCallback(() => {
    setExpandedFeature(
      isExpandedFeature(FeatureNames.ROOM_ACCESS)
        ? null
        : FeatureNames.ROOM_ACCESS
    )
  }, [isExpandedFeature, setExpandedFeature])
  const toggleMobileAppFeature = useCallback(() => {
    setExpandedFeature(
      isExpandedFeature(FeatureNames.MOBILE_APP)
        ? null
        : FeatureNames.MOBILE_APP
    )
  }, [isExpandedFeature, setExpandedFeature])

  return (
    <>
      <Header />
      <Section id="produkt">
        <SectionContent>
          <CollapseStyles />
          <SectionHeading>Główne cechy systemu CelPal</SectionHeading>

          <OutstandingWrapper>
            <FeatureItem>
              <FeatureTitle>
                Elektroniczna książka rejestru pobytu na strzelnicy
              </FeatureTitle>
              <SectionText>
                Wystarczą tylko 3 kroki, żeby goście Twojej strzelnicy
                błyskawicznie wpisali się do książki.
                <Collapse isOpened={isExpandedFeature(FeatureNames.BOOK)}>
                  Wszystko, co musi zrobić osoba wpisująca się do książki to
                  podać imię i nazwisko oraz adres lub numer pozwolenia i złożyć
                  podpis na ekranie.
                  <br />
                  <br />
                  W skrócie:
                  <br />
                  <FeatureListItem>
                    Profesjonalizm, prostota i szybkość działania
                  </FeatureListItem>
                  <FeatureListItem>
                    Obsługa w języku polskim i angielskim
                  </FeatureListItem>
                  <FeatureListItem>
                    Bezpieczeństwo danych zgodne z wymaganiami RODO
                  </FeatureListItem>
                  <FeatureListItem>
                    Zgodność z Rozporządzeniem MSWiA w sprawie wzorcowego
                    regulaminu strzelnic
                  </FeatureListItem>
                  <FeatureListItem>
                    Automatyczne podpowiadanie adresu
                  </FeatureListItem>
                  <FeatureListItem>
                    Podgląd wpisów w czasie rzeczywistym w panelu
                    administracyjnym
                  </FeatureListItem>
                  <FeatureListItem>
                    Przegląd wpisów historycznych
                  </FeatureListItem>
                  <FeatureListItem>
                    Możliwość weryfikacji i edycji wpisów przed zatwierdzeniem
                  </FeatureListItem>
                  <FeatureListItem>
                    Eksport wpisów do PDF lub XLS
                  </FeatureListItem>
                </Collapse>
                <ShowMore
                  onClick={toggleBookFeature}
                  isOpen={isExpandedFeature(FeatureNames.BOOK)}
                />
              </SectionText>
            </FeatureItem>
            <FeatureItem>
              <FeatureTitle>Aplikacja mobilna</FeatureTitle>
              <SectionText>
                Każdy, kto zainstaluje aplikację mobliną <LogoInline /> może
                wpisać się do książki pobytu na Twojej strzelnicy jeszcze
                szybciej.
                <Collapse isOpened={isExpandedFeature(FeatureNames.MOBILE_APP)}>
                  W skrócie:
                  <br />
                  <FeatureListItem>Prosta i szybka rejestracja</FeatureListItem>
                  <FeatureListItem>Mapa strzelnic</FeatureListItem>
                  <FeatureListItem>
                    Wpisanie się do książki możliwe tylko w odległości 300m od
                    strzelnicy
                  </FeatureListItem>
                  <FeatureListItem>
                    Podpis składany palcem na ekranie telefonu
                  </FeatureListItem>
                </Collapse>
                <ShowMore
                  onClick={toggleMobileAppFeature}
                  isOpen={isExpandedFeature(FeatureNames.MOBILE_APP)}
                />
              </SectionText>
            </FeatureItem>
            <FeatureItem>
              <FeatureTitle>Rejestr klubowiczów</FeatureTitle>
              <SectionText>
                Jeśli prowadzisz sportowy lub kolekcjonerski klub strzelecki,
                dzięki
                <LogoInline /> możesz prowadzić ewidencję członków klubu.
                <Collapse isOpened={isExpandedFeature(FeatureNames.REGISTRY)}>
                  W skrócie:
                  <br />
                  <FeatureListItem>
                    Tworzenie, edycja i przegląd danych klubowiczów
                  </FeatureListItem>
                  <FeatureListItem>
                    Zaawansowane filtrowanie i sortowanie przeglądanych wyników
                  </FeatureListItem>
                  <FeatureListItem>
                    Ponad 30 różnych informacji możliwych do zapisania
                  </FeatureListItem>
                  <FeatureListItem>
                    Pełny lub częściowy eksport danych do pliku XLS
                  </FeatureListItem>
                </Collapse>
                <ShowMore
                  onClick={toggleRegistryFeature}
                  isOpen={isExpandedFeature(FeatureNames.REGISTRY)}
                />
              </SectionText>
            </FeatureItem>
            <FeatureItem>
              <FeatureTitle>Karta członkowska</FeatureTitle>
              <SectionText>
                Jeśli prowadzisz klub strzelecki, możesz zamówić karty
                członkowskie dla swoich klubowiczów, żeby umożliwić im
                błyskawiczne wpisywanie się do książki bez konieczności
                podawania danych za każdym razem.
                <Collapse isOpened={isExpandedFeature(FeatureNames.CARD)}>
                  <br />
                  Ponadto, członkowie Twojego klubu skorzystają z dobrodziejstw
                  elektronicznej książki nie tylko na Twojej strzelnicy, ale{' '}
                  <strong>
                    na każdej strzelnicy klubowej zrzeszonej w programie CelPal
                  </strong>
                  .
                  <br />
                  <br />
                  Jak to działa?
                  <FeatureListItem>
                    Każdy klubowicz otrzymuje kartę z unikalnym identyfikatorem
                    przypisanym do swojego konta w systemie.
                  </FeatureListItem>
                  <FeatureListItem>
                    Aby wpisać się do książki, wystarczy przyłożyć swoją kartę
                    do czytnika podłączonego do tabletu z książką i złożyć
                    podpis.
                  </FeatureListItem>
                </Collapse>
                <ShowMore
                  onClick={toggleMemberCardFeature}
                  isOpen={isExpandedFeature(FeatureNames.CARD)}
                />
              </SectionText>
            </FeatureItem>
            <FeatureItem>
              <FeatureTitle>Kontrola dostępu</FeatureTitle>
              <SectionText>
                Wykorzystujesz karty RFID do zarządzania dostępem do pomieszczeń
                na Twojej strzelnicy? Dzięki <LogoInline /> możesz zarządzać
                dostępem dla każdego z klubowiczów lub pracowników.
                <Collapse
                  isOpened={isExpandedFeature(FeatureNames.ROOM_ACCESS)}
                >
                  W skrócie:
                  <br />
                  <FeatureListItem>
                    Tworzenie własnych bramek dostępu
                  </FeatureListItem>
                  <FeatureListItem>
                    Nadawanie uprawnień wybranym osobom
                  </FeatureListItem>
                  <FeatureListItem>
                    Tworzenie bramek automatycznych (aktywowanych dla wszystkich
                    aktywnych klubowiczów)
                  </FeatureListItem>
                  <FeatureListItem>
                    Eksport uprawnień do pliku CSV w celu użycia w zewnętrznym
                    systemie
                  </FeatureListItem>
                </Collapse>
                <ShowMore
                  onClick={toggleRoomAccessFeature}
                  isOpen={isExpandedFeature(FeatureNames.ROOM_ACCESS)}
                />
              </SectionText>
            </FeatureItem>
            <FeatureItem>
              <FeatureTitle>Stały dostęp online</FeatureTitle>
              <SectionText>
                Korzystaj z <LogoInline /> w dowolnym miejscu i czasie, na
                dowolnym urządzeniu z dostępem do internetu.
              </SectionText>
            </FeatureItem>
          </OutstandingWrapper>
          <SectionHeading>To nie wszystko!</SectionHeading>
          <SectionText large>
            Poznaj korzyści systemu <LogoInline /> związane z bezpieczeństwem.
          </SectionText>
          <SectionText>
            <a href="/bezpieczenstwo">Przejdź do bezpieczeństwa</a>
          </SectionText>
        </SectionContent>
      </Section>
      <Footer />
    </>
  )
}

const CollapseStyles = createGlobalStyle`
  .ReactCollapse--collapse {
    transition: height 200ms;
  }
`

const OutstandingWrapper = styled.div`
  background: #fafafa;
  border-radius: 20px;
  padding: 10px 40px;
  margin: 20px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    margin: 20px -40px;
  }
`

const FeatureItem = styled.div`
  margin: 24px 0 36px;
`

const FeatureTitleText = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`

const FeatureIcon = styled(IoIosCheckmarkCircleOutline)`
  margin-right: 12px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
`

const FeatureTitle = ({ children, ...rest }) => (
  <FeatureTitleText {...rest}>
    <FeatureIcon />
    {children}
  </FeatureTitleText>
)

const ShowMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 8px 0 8px 12px;
  font-weight: bold;
  color: #2277c1;

  &:hover {
    color: #216ab2;
  }
`

const MoreIcon = styled.div`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`

const ShowMore = ({ isOpen, ...rest }) => (
  <ShowMoreWrapper {...rest}>
    {isOpen ? 'mniej' : 'więcej'}
    <MoreIcon as={isOpen ? MdArrowUpward : MdArrowDownward} />
  </ShowMoreWrapper>
)

const FeatureListItemStyle = styled.div`
  margin: 8px 0;
`
const FeatureListIcon = styled(MdCheck)`
  margin-left: 36px;
  margin-right: 8px;
`
const FeatureListItem = ({ children }) => (
  <FeatureListItemStyle>
    <FeatureListIcon /> {children}
  </FeatureListItemStyle>
)
