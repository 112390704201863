import React from 'react'
import styled from 'styled-components'
import { SectionContent, SectionSubHeading, SectionText } from '../Section'
import { Logo } from '../Logo'
import { useMobile } from '../../hooks/useMobile'
import { IoLogoFacebook } from 'react-icons/io'

export const Footer = props => {
  const isMobile = useMobile(480)

  return (
    <Wrapper {...props}>
      <SectionContent>
        <ContactLayout isMobile={isMobile}>
          <div>
            <SectionSubHeadingStyled>Kontakt</SectionSubHeadingStyled>
            <SectionText>
              <Link href="mailto:kontakt@celpal.net">kontakt@celpal.net</Link>
              <Link
                href="https://www.facebook.com/CelPalApp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoFacebook size="24px" /> Facebook
              </Link>
            </SectionText>
          </div>
          <Company isMobile={isMobile}>
            <LogoStyled light />
            <br />
            jest marką Kodeusz Sp. z o. o.
            <br />
            ul. Jana Heweliusza 11/819
            <br />
            80-890 Gdańsk
            <br />
            <br />
            NIP 5833390660
            <br />
            KRS 0000830481
          </Company>
        </ContactLayout>
      </SectionContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #282c34;
  color: #fff;
  overflow: hidden;
  margin-top: 60px;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  color: #8cc9ff;
`

const LogoStyled = styled(Logo)`
  width: 140px;
  margin-bottom: 10px;
`

const SectionSubHeadingStyled = styled(SectionSubHeading)`
  margin-top: 0;
`

const ContactLayout = styled.div`
  display: flex;
  flex-direction: ${p => (p.isMobile ? 'column' : 'row')};
  align-items: ${p => (p.isMobile ? 'center' : 'flex-start')};
  text-align: ${p => (p.isMobile ? 'center' : 'left')};
  justify-content: space-between;
`

const Company = styled.div`
  text-align: ${p => (p.isMobile ? 'center' : 'right')};
  margin-top: ${p => (p.isMobile ? '16px' : '0')};
`
