import React from 'react'
import styled from 'styled-components'
import { SectionContent, SectionHeading, SectionText } from '../Section'
import { MdCheck } from 'react-icons/md'
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  Link
} from '@material-ui/core'
import { plural } from '../../helpers/plural'
import { FaFacebookMessenger } from 'react-icons/fa'

const getMailLink = offerVariant => {
  const address = 'kontakt@celpal.net'
  const subject = encodeURIComponent('Zapytanie o ofertę')
  const body = encodeURIComponent(
    `Jestem zainteresowany ofertą ${offerVariant}. Proszę o kontakt.`
  )
  return `mailto:${address}?subject=${subject}&body=${body}`
}

const bundles = [
  {
    name: 'Minimum',
    emailText: 'Minimum',
    price: '29',
    facilityLimit: 1,
    entryLimit: 10,
    colors: {
      main: '#0075da',
      alt: '#eef7ff'
    },
    features: [
      'Elektroniczna książka rejestru pobytu na strzelnicy',
      'Aplikacja mobilna',
      'Podgląd wpisów w czasie rzeczywistym',
      'Podgląd wpisów historycznych',
      'Eksport wpisów',
      'System weryfikacji wpisów'
    ]
  },
  {
    name: 'Podstawowy',
    emailText: 'Podstawową',
    price: '199',
    colors: {
      main: '#0f00da',
      alt: '#f0f0ff'
    },
    features: [
      'Elektroniczna książka rejestru pobytu na strzelnicy',
      'Aplikacja mobilna',
      'Podgląd wpisów w czasie rzeczywistym',
      'Podgląd wpisów historycznych',
      'Eksport wpisów',
      'System weryfikacji wpisów'
    ]
  },
  // {
  //   name: 'Rozszerzony',
  //   emailText: 'Rozszerzoną',
  //   price: '249',
  //   colors: {
  //     main: '#ffa600',
  //     alt: '#fff6ec'
  //   },
  //   features: [
  //     'Elektroniczna książka rejestru pobytu na strzelnicy',
  //     'Aplikacja mobilna',
  //     'Podgląd wpisów w czasie rzeczywistym',
  //     'Podgląd wpisów historycznych',
  //     'Eksport wpisów',
  //     'System weryfikacja wpisów',
  //     'Rejestr klubowiczów',
  //     'Eksport danych'
  //   ]
  // },
  {
    name: 'Premium',
    emailText: 'Premium',
    price: '499',
    colors: {
      main: '#f34e00',
      alt: '#fff3f0'
    },
    features: [
      'Elektroniczna książka rejestru pobytu na strzelnicy',
      'Aplikacja mobilna',
      'Podgląd wpisów w czasie rzeczywistym',
      'Podgląd wpisów historycznych',
      'Eksport wpisów',
      'System weryfikacji wpisów',
      'Rejestr klubowiczów',
      'Eksport danych (XLS / PDF)',
      'Karty członkowskie',
      'Płatności członkowskie online',
      'Wydruk zaświadczeń o członkostwie',
      'Weryfikacja zaświadczeń dla WPA',
      'Wykaz pobytów na strzelnicy',
      'Deklaracje członkowskie',
      'Integracja z systemami kontroli dostępu',
      <>
        Przestrzeń klubowa na{' '}
        <Link href="https://forum.celpal.net">forum.celpal.net</Link>
      </>
    ]
  }
]

const allFeatures = bundles.reduce((features, bundle) => {
  return [
    ...features,
    ...bundle.features.filter(feature => !features.includes(feature))
  ]
}, [])

export const Pricing = () => {
  const limitedFeatureShortText = limit => limit || 'bez ograniczeń'

  return (
    <SectionContentStyled>
      <SectionHeading>Oferta</SectionHeading>
      <Box mb={5} maxWidth={500}>
        <SectionText>
          Przygotowaliśmy dla Państwa gotowe pakiety abonamentowe.
          <br />
          Jeśli nie znaleźli Państwo odpowiedniego pakietu,{' '}
          <strong>zachęcamy do kontaktu</strong>, np. poprzez{' '}
          <a onClick={() => window.FB.CustomerChat.showDialog()}>
            <Box display="inline-flex" alignItems="center">
              czat&nbsp;
              <FaFacebookMessenger size={24} />
            </Box>
          </a>
          . Z chęcią dostosujemy pakiet do Państwa potrzeb.
        </SectionText>
      </Box>
      <Hidden smDown>
        <PricingTable stickyHeader>
          <PricingTableHead>
            <TableRow>
              <TableCell />
              {bundles.map(bundle => (
                <BundleTableCell key={bundle.name} color={bundle.colors.main}>
                  <PricingName>Pakiet {bundle.name}</PricingName>
                  <PricingPrice>{bundle.price} zł</PricingPrice>
                  <PricingPeriod>+ VAT / 1 miesiąc</PricingPeriod>
                </BundleTableCell>
              ))}
            </TableRow>
          </PricingTableHead>
          <PricingTableBody>
            <TableRow>
              <TableCell>Ilość strzelnic</TableCell>
              {bundles.map(bundle => (
                <BundleTableCell key={bundle.name} color={bundle.colors.alt}>
                  {limitedFeatureShortText(bundle.facilityLimit)}
                </BundleTableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Dzienny limit wpisów</TableCell>
              {bundles.map(bundle => (
                <BundleTableCell key={bundle.name} color={bundle.colors.alt}>
                  {limitedFeatureShortText(bundle.entryLimit)}
                </BundleTableCell>
              ))}
            </TableRow>
            {allFeatures.map((feature, i) => (
              <TableRow key={i}>
                <TableCell>{feature}</TableCell>
                {bundles.map(bundle => (
                  <BundleTableCell key={bundle.name} color={bundle.colors.alt}>
                    {bundle.features.includes(feature) && (
                      <PricingFeatureIcon />
                    )}
                  </BundleTableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              {bundles.map(bundle => (
                <BundleTableCell key={bundle.name} color={bundle.colors.alt}>
                  <OrderButton
                    as="a"
                    href={getMailLink(bundle.emailText)}
                    target="_blank"
                  >
                    Wybierz
                  </OrderButton>
                </BundleTableCell>
              ))}
            </TableRow>
          </PricingTableBody>
        </PricingTable>
      </Hidden>
      <Hidden mdUp>
        <PricingTiles>
          {bundles.map((bundle, i) => (
            <PricingTile key={bundle.name} color={bundle.colors.alt}>
              <TileHeading bundle={bundle} />
              <PricingFeatures>
                {bundle.features.map(feature => (
                  <PricingFeature
                    key={feature}
                    strong={
                      bundles[i - 1] &&
                      !bundles[i - 1].features.includes(feature)
                    }
                  >
                    {feature}
                  </PricingFeature>
                ))}
              </PricingFeatures>
              <PricingTileFooter>
                <OrderButton
                  as="a"
                  href={getMailLink(bundle.emailText)}
                  target="_blank"
                >
                  Napisz do nas
                </OrderButton>
                {/* TODO: implement Order button */}
                {/*<OrderButton>Zamów teraz</OrderButton>*/}
              </PricingTileFooter>
            </PricingTile>
          ))}
        </PricingTiles>
      </Hidden>
    </SectionContentStyled>
  )
}

const TileHeading = ({ bundle }) => {
  const pluralFacilityLimit = limit =>
    limit
      ? plural(limit, 'strzelnica', 'strzelnice', 'strzelnic')
      : 'Bez limitu strzelnic'
  const pluralEntryLimit = limit =>
    limit
      ? plural(limit, 'wpis dziennie', 'wpisy dziennie', 'wpisów dziennie')
      : 'Bez limitu wpisów'

  return (
    <PricingTileHeading color={bundle.colors.main}>
      <PricingName>Pakiet {bundle.name}</PricingName>
      <PricingPrice>{bundle.price} zł</PricingPrice>
      <PricingPeriod>+ VAT / 1 miesiąc</PricingPeriod>
      <PricingRangeLimit>
        {bundle.facilityLimit} {pluralFacilityLimit(bundle.facilityLimit)}
      </PricingRangeLimit>
      <PricingRangeLimit>
        {bundle.entryLimit} {pluralEntryLimit(bundle.entryLimit)}
      </PricingRangeLimit>
    </PricingTileHeading>
  )
}

const PricingTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 8px;

  th {
    color: #fff;
  }
`

const PricingTableHead = styled(TableHead)`
  th {
    font-family: 'Lato', sans-serif;
    line-height: 1.6;
    text-align: center;
  }
`

const PricingTableBody = styled(TableBody)`
  td + td {
    text-align: center;
  }
`

const SectionContentStyled = styled(SectionContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

const PricingFeatureWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 6px 0;
`
const PricingFeatureIcon = styled(MdCheck)`
  margin-top: 4px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: #008000;
`
const PricingFeature = ({ children, strong }) => (
  <PricingFeatureWrapper>
    <PricingFeatureIcon />
    {strong ? <strong>{children}</strong> : children}
  </PricingFeatureWrapper>
)

const PricingTiles = styled.div`
  margin: 20px 0 60px;
`

const PricingTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: ${p => p.color};
`

const PricingTileHeading = styled.div`
  padding: 36px 0 16px;
  background: ${p => p.color};
  color: #fff;
  border-bottom: 1px solid #e2e2e2;
  text-align: center;
`
const PricingName = styled.div`
  font-size: 20px;
`
const PricingPrice = styled.div`
  font-size: 40px;
  font-weight: bold;
`
const PricingPeriod = styled.div`
  font-size: 13px;
  color: #fefefe;
  margin-bottom: 18px;
`

const PricingRangeLimit = styled.div`
  color: #fefefe;
`

const PricingFeatures = styled(SectionText)`
  flex-grow: 1;
  padding: 24px;
`
const PricingTileFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`
const OrderButton = styled.div`
  display: inline-block;
  background: #0075da;
  color: #fff;
  font-size: 17px;
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: #0065c9;
  }
`

const BundleTableCell = styled(TableCell)`
  && {
    background-color: ${p => p.color};
  }
`
