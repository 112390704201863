import React, { useState } from 'react'
import styled from 'styled-components'

export const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(window.localStorage.getItem('cookies-consent') !== '1')

  const hideBanner = () => {
    window.localStorage.setItem('cookies-consent', '1')
    setShowBanner(false)
  }

  if (showBanner) {
    return (
      <CookiesInfo>
        Używamy plików cookies <CookiesButton onClick={hideBanner}>OK</CookiesButton>
      </CookiesInfo>
    )
  }

  return null
}

const CookiesInfo = styled.div`
  position: fixed;
  bottom: 16px;
  left: 12px;
  font-size: 12px;
  background: #f7f7f7;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
`

const CookiesButton = styled.button`
  background: #1385e4;
  border: none;
  color: #fff;
  font-size: 11px;
  border-radius: 4px;
  padding: 4px 8px;
  margin-left: 8px;
  outline: none;
  cursor: pointer;
`
